import React, { useState, useEffect } from "react";
import {
  Image,
  LocationOn,
  Description,
  PlayCircleFilled
} from "@material-ui/icons";
import {
  Box,
  Grid,
  CardMedia,
  Typography,
  withStyles,
  CardContent,
  Theme,
} from "@material-ui/core";

import formatText from "../../helpers/formatText";
import WhatsAppIcon from "../../static/WhatsApp_icon.png";
import ClickToAction from "../Chatbox/MessageTypes/ClickToAction";
import InteractiveAction from "../Chatbox/MessageTypes/InteractiveAction";
import InteractiveButtons from "../Chatbox/MessageTypes/InteractiveButtons";
import { replaceParameters } from "../../helpers/replaceParameters";
import OrderCard from "../OrderCard/orderCard";
import LimitedTimeOffer from "../Chatbox/MessageTypes/LimitedTimeOffer";
import axios from "axios";

export const bg_colors:any = {
    IMAGE: "rgb(250, 173, 32, 0.15)",
    VIDEO: "rgb(95, 102, 205, 0.15)",
    FILE: "rgb(236, 64, 122, 0.15)",
    LOCATION: "rgb(255 5 5 / 17%)",
  };


  export const icon_colors:any = {
    IMAGE: "rgb(250, 173, 32)",
    VIDEO: "rgb(95, 102, 205)",
    FILE: "rgb(236, 64, 122)",
    LOCATION: "rgb(239 0 0)",
  };
  
function Media(props:any) {
  const {
    type,
    classes,
    bigIcon,
    payload,
    maxHeight,
    isHideIcon,
    showDisclaimer,
    // Carousel
    carouselIndex,
    carouselCards = [],
    selectedTemplate,
    cards,
    formattedButtons,
    key,
    paymentParams,
    formattedLimitedTimeOffer,
    isHideImagePreview = false,
  } = props;
  const [mediaData, setMediaData] = useState({} as any);

  const { text = "", caption = "" } = payload;

  let body = (text || caption).split(" | [")[0];
  const hideIcon = isHideIcon && type !== "CAROUSEL";
  const disclaimerMargin = isHideImagePreview
    ? 0
    : props.disclaimerMargin || (hideIcon ? 0 : 16);

  const carouselLength = carouselCards.length;
  const isCarousel = carouselLength > 0;

  if (carouselCards?.length) {
    let bodyParameters = cards?.[carouselIndex]?.components
      ?.find((c:any) => c.type === "BODY")
      ?.parameters?.map((p:any) => p.text);

    if (!bodyParameters?.length) {
      bodyParameters = carouselCards?.[carouselIndex]?.components
        ?.find((c:any) => c.type === "BODY")
        ?.example?.body_text?.[0]?.map((text:any) => text);
    }

    body = replaceParameters(body, bodyParameters);
  }

  const getMediaType = () => {
    // Return template type directly if it is not a carousel
    if (!isCarousel) return headerType;

    // Call carousel card to get media type
    const carouselCard = carouselCards?.[carouselIndex] || {};
    const headerComponent = carouselCard?.components?.find(
      (component:any) => component.type === "HEADER"
    );
    return headerComponent?.format;
  };

  const onMediaFallback:any = () => {
    console.log("fallback triggered!!");
  };

  const { limitedTimeOffer, buttons = [] } = selectedTemplate || {};
  const headerType = selectedTemplate?.headerType || type;
  const mediaType = getMediaType();
  const mediaUrl =
    cards?.[carouselIndex]?.components?.find(({ type }:any) => type === "HEADER")
      ?.parameters?.[0]?.[mediaType.toLowerCase()]?.link || payload.url;

  /**
   * Fetch media as BLOB if present
   */
  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(`${mediaUrl}?not-from-cache-please`, {
          responseType: "blob",
          headers: { Authorization: "" },
        });
        const contentType = response.headers["content-type"]; // Extract MIME type
        setMediaData({
          contentType,
          media: URL.createObjectURL(response.data),
        });
      } catch (error) {
        console.error("Error fetching media:", error);
      }
    };
    if (mediaUrl && !mediaData?.media) {
      fetchMedia();
    }
  }, [mediaUrl]);

  const getCardMediaProps :any= () => {
    const FileIcon = () => {
      return (
        <Box
          height="140px"
          bgcolor={bg_colors[headerType] ?? "#f8f8f8"}
          style={{
            border: "16px solid white",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Description
            className={classes.icon}
            style={{
              color: icon_colors[headerType],
            }}
          />
        </Box>
      );
    };

    const cardMediaProps =
      headerType === "FILE" && mediaData.contentType !== "application/pdf"
        ? {
            component: FileIcon,
          }
        : {
            component: mediaType === "IMAGE" ? "img" : "iframe",
            src: mediaData.media,
          };

    return cardMediaProps;
  };

  return (
    <Box style={{ width: "100%" }} key={key}>
      {/* Main message box */}
      <Grid style={{ display: "flex" }}>
        <Box
          className={
            props.hideMessageTopEdge
              ? `${classes.normalMessage} ${classes.normalReceived}`
              : `${classes.message} ${classes.received}`
          }
          style={
            props.isHideIcon && type !== "CAROUSEL"
              ? { overflow: "hidden" }
              : {}
          }
        >
          {/* Whatsapp icon */}
          {!props.isHideIcon && (
            <img
              src={WhatsAppIcon}
              alt="WhatsApp"
              className={
                !bigIcon ? classes.whatsappIcon : classes.bigWhatsappIcon
              }
            />
          )}

          {/* Message Header Media Preview */}
          {!["TEXT", "ORDER_STATUS"].includes(type) &&
          !["TEXT"].includes(headerType) ? (
            mediaUrl ? (
              type === "AUDIO" ? (
                <audio
                  controls
                  loop
                  src={payload.url}
                  style={{ width: "100%" }}
                />
              ) : (
                <CardMedia
                  className={classes.media}
                  alt="media"
                  title={payload.filename || "Download media"}
                  {...getCardMediaProps()}
                  onError={onMediaFallback}
                  style={
                    type === "LIMITED_TIME_OFFER"
                      ? {
                          borderRadius: "20px 20px 0 0",
                          paddingBottom: "0px",
                        }
                      : {}
                  }
                />
              )
            ) : [
                "ORDER_DETAILS",
                "CATALOGUE_MESSAGE",
                "MULTI_PRODUCT_MESSAGE",
              ].includes(type) ? (
              <OrderCard
                type={type}
                total={paymentParams?.amount}
                quantity={paymentParams?.quantity}
                productName={paymentParams?.productName}
              />
            ) : isHideImagePreview ? null : (
              <Box
                height="140px"
                bgcolor={bg_colors[headerType] ?? "#f8f8f8"}
                style={{
                  border: "16px solid white",
                  borderRadius:
                    type !== "LIMITED_TIME_OFFER" ? "20px" : "20px 20px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                ...(type !== "LIMITED_TIME_OFFER" ? { borderBottom: 0 }: {})
                }}
              >
                {headerType === "IMAGE" ? (
                  <Image
                    className={classes.icon}
                    style={{ color: icon_colors[headerType] }}
                  />
                ) : headerType === "FILE" ? (
                  <Description
                    className={classes.icon}
                    style={{ color: icon_colors[headerType] }}
                  />
                ) : headerType === "VIDEO" ? (
                  <PlayCircleFilled
                    className={classes.icon}
                    style={{ color: icon_colors["VIDEO"] }}
                  />
                ) : headerType === "TEXT" || type === "ORDER_DETAILS" ? (
                  <Box style={{ height: 300, width: 300 }} />
                ) : type === "LOCATION" ? (
                  <LocationOn
                    className={classes.icon}
                    style={{ color: icon_colors["LOCATION"] }}
                  />
                ) : type === "CAROUSEL" ? (
                  <Box style={{ display: "flex" }}>
                    {payload.mediaType === "IMAGE" ? (
                      <Image
                        className={classes.icon}
                        style={{ color: icon_colors["IMAGE"] }}
                      />
                    ) : payload.mediaType === "VIDEO" ? (
                      <PlayCircleFilled
                        className={classes.icon}
                        style={{ color: icon_colors["VIDEO"] }}
                      />
                    ) : null}
                  </Box>
                ) : (
                  <Box style={{ display: "flex" }}>
                    <Image
                      className={classes.icon}
                      style={{ color: icon_colors["IMAGE"] }}
                    />
                    <PlayCircleFilled
                      className={classes.icon}
                      style={{ color: icon_colors["VIDEO"] }}
                    />
                    <Description
                      className={`${classes.icon} ${classes.fileIcon}`}
                      style={{ color: icon_colors["FILE"] }}
                    />
                  </Box>
                )}
              </Box>
            )
          ) : (
            ["TEXT"].includes(headerType) &&
            ["ORDER_DETAILS"].includes(type) && (
              <OrderCard
                type={type}
                total={paymentParams?.amount}
                quantity={paymentParams?.quantity}
                productName={paymentParams?.productName}
              />
            )
          )}

          {/* Header section */}
          {payload.headerText && (
            <Box style={{ overflowWrap: "break-word" }}>
              <Typography className={classes.header}>
                {payload.headerText}
              </Typography>
            </Box>
          )}

          {type === "LIMITED_TIME_OFFER" && (
            <Box
              style={{
                padding: "0 16px",
                borderRadius: "0 0 20px 20px",
                minWidth: "220px",
              }}
            >
              <LimitedTimeOffer
                limitedTimeOffer={limitedTimeOffer}
                formattedLimitedTimeOffer={formattedLimitedTimeOffer}
                buttons={buttons}
                formattedButtons={formattedButtons}
              />
            </Box>
          )}

          {/* No caption in audio type message*/}
          {type !== "AUDIO" && (
            <CardContent
              style={
                maxHeight
                  ? {
                      maxHeight,
                      overflowX: "auto",
                      padding: "8px 16px",
                    }
                  : {
                      padding: "8px 16px",
                    }
              }
            >
              {type === "ORDER_STATUS" && (
                <Typography
                  variant="h5"
                  color="primary"
                  align="left"
                  style={
                    type === "TEXT"
                      ? {
                          paddingTop: "8px",
                          minHeight: 40,
                        }
                      : props.lineHeight || props.minHeight
                      ? {
                          overflow: "hidden",
                          minHeight: `${
                            props.lineHeight
                              ? props.lineHeight * props.lines
                              : props.minHeight || 2.5
                          }em`,
                          maxHeight: `${props.lineHeight * props.lines}em`,
                          lineHeight: `${props.lineHeight}em`,
                        }
                      : {}
                  }
                  className={classes.messageBox}
                >
                  Status: {"{{Order Status}}"}
                </Typography>
              )}
              <Typography
                variant="h5"
                color="primary"
                align="left"
                style={
                  type === "TEXT"
                    ? {
                        paddingTop: "8px",
                        minHeight: 40,
                      }
                    : props.lineHeight || props.minHeight
                    ? {
                        overflow: "hidden",
                        minHeight: `${
                          props.lineHeight
                            ? props.lineHeight * props.lines
                            : props.minHeight || 2.5
                        }em`,
                        maxHeight: `${props.lineHeight * props.lines}em`,
                        lineHeight: `${props.lineHeight}em`,
                      }
                    : {}
                }
                className={classes.messageBox}
                dangerouslySetInnerHTML={{
                  __html: formatText(body),
                }}
              />
            </CardContent>
          )}

          {/* Footer section */}
          {payload.footerText && (
            <Box style={{ overflowWrap: "break-word" }}>
              <Typography className={classes.footer}>
                {payload.footerText}
              </Typography>
            </Box>
          )}

          {payload.actionType === "CAT" && (
            <ClickToAction
              theme={1}
              type={type}
              preview={true}
              payload={payload}
            />
          )}
        </Box>
      </Grid>
      {/* Button based actions */}
      {!isHideImagePreview &&
        payload.actionType !== "CAT" &&
        payload.actionType !== "None" && (
          <Box
            width="100%"
            maxWidth="340px"
            className={classes.agentMessageAction}
          >
            {payload.actionType === "All" ? (
              <InteractiveButtons
                theme={1}
                type={type}
                preview={true}
                payload={payload}
                selectedTemplate={selectedTemplate}
                cards={cards}
                carouselIndex={carouselIndex}
                formattedButtons={formattedButtons}
              />
            ) : (
              <InteractiveAction
                payload={payload}
                type={type}
                theme={1}
                preview={true}
              />
            )}
          </Box>
        )}
      {/* Disclaimer text here */}
      <Box style={{ height: disclaimerMargin }} />
      {showDisclaimer && (
        <Typography
          className={classes.disclaimer}
          style={{ ...( type === "TEXT" ? { color: "transparent"} : {}) }}
        >
          Disclaimer: This is just a graphical representation of the message
          that will be delivered. Actual message will consist of media selected
          and may appear different.
        </Typography>
      )}

      <Box style={{ height: disclaimerMargin }} />
    </Box>
  );
}

const styles:any = (theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: "340px",
    background: theme.palette.primary.light,
  },

  message: {
    clear: "both",
    background: "white",
    maxWidth: "340px",
    width: "100%",
    position: "relative",
    lineHeight: "24px",
    fontSize: "16px",

    "&::after": {
      position: "absolute",
      content: '""',
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },

  received: {
    background: "white",
    borderRadius: "0px 5px 5px 5px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) -0.5px 1.5px 0px 0px",
    filter:
      "drop-shadow(0px 0.5px 0px rgba(60, 64, 67, 0.3)) drop-shadow(1px 1px 2px rgba(60, 64, 67, 0.15))",

    "&::after": {
      content: '""',
      borderWidth: "0px 10px 10px 0",
      borderColor: `transparent #fff transparent transparent`,
      top: "0",
      left: "-10px",
    },
  },

  received2: {
    background: "white",
    borderRadius: 5,
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) -0.5px 1.5px 0px 0px",
    filter:
      "drop-shadow(0px 0.5px 0px rgba(60, 64, 67, 0.3)) drop-shadow(1px 1px 2px rgba(60, 64, 67, 0.15))",
  },

  media: {
    height: 140,
    width: "calc(100% - 32px)",
    padding: "16px",
    borderRadius: "20px",
    border: 0,
  },

  messageBox: {
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    color: "#000000",
  },

  agentMessageAction: {
    "& .defaultTheme": {
      borderRadius: "6px",
      maxWidth: "100%",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },

  header: {
    fontWeight: "600",
    padding: "8px 16px",
  },

  footer: {
    color: "#A9A9A9",
    fontSize: "14px",
    fontWeight: "500",
    padding: "0px 16px",
    paddingBottom: "8px",
  },

  icon: {
    width: "50px",
    height: "50px",
    color: "#a0a0a0",
  },

  whatsappIcon: {
    width: "30px",
    height: "30px",
    position: "absolute",
    top: -15,
    left: -15,
    zIndex: 1,
  },

  bigWhatsappIcon: {
    width: "50px",
    height: "50px",
    position: "absolute",
    top: -25,
    left: -25,
    zIndex: 1,
  },

  disclaimer: {
    color: "#A9A9A9",
    fontSize: "12px",
    maxWidth: 340,
  },

  fileIcon: {
    marginTop: "3px",
    height: "45px",
  },

  deleteIcon: {
    left: 327,
    zIndex: 1,
    top: -10,
    width: "25px",
    height: "25px",
    cursor: "pointer",
    position: "absolute",
    color: "rgb(250, 92, 92)",
  },
  normalMessage: {
    clear: "both",
    background: "white",
    maxWidth: "340px",
    width: "100%",
    position: "relative",
    lineHeight: "24px",
    fontSize: "16px",
  },
  normalReceived: {
    background: "white",
    borderRadius: 5,
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) -0.5px 1.5px 0px 0px",
    filter:
      "drop-shadow(0px 0.5px 0px rgba(60, 64, 67, 0.3)) drop-shadow(1px 1px 2px rgba(60, 64, 67, 0.15))",
  },
});

export default React.memo(withStyles(styles)(Media), (oldProps, newProps) => {
  const arePropsEqual = JSON.stringify(oldProps) === JSON.stringify(newProps);
  return arePropsEqual;
});
