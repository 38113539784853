import React from "react";
import { isEmpty } from "lodash";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Media from "../TemplateMessagePreview/Media";
import {
  replaceParameters,
  replaceCarouselParameters,
} from "../../helpers/replaceParameters";
import {
  AddOutlined,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Delete,
} from "@material-ui/icons";

const CarouselTemplateMessagePreview = (props:any) => {
  const {
    media,
    parameters,
    selectedTemplate,
    // Carousel Props
    setCarouselIndex,
    carouselIndex = 0,
    addCarouselTemplate,
    cards,
  } = props;

  const type = selectedTemplate?.type;
  let ctaURL = selectedTemplate?.templateCTAUrl;
  const text = replaceParameters(
    selectedTemplate?.format || selectedTemplate?.payload?.text,
    parameters
  );

  const isCarousel = type === "CAROUSEL";
  const { carouselCards = [] } = selectedTemplate;

  // For pre-existing templates
  if (!ctaURL) {
    const urlObject = (selectedTemplate?.callToAction || []).filter(
      (e:any) => e.type === "URL"
    );

    if (urlObject.length > 0) {
      ctaURL = urlObject[0].buttonValue;
      if (parameters && parameters.length > 0) {
        const param = parameters[parameters.length - 1];
        if (param)
          ctaURL = ((string, replacement) => {
            return string.replace(/{{(\d+)}}/g, function(match:any, g1:any) {
              return `[${replacement}]`;
            });
          })(urlObject[0].buttonValue, param);
      }
    }
  }

  if (selectedTemplate?.sampleCTAUrl && !ctaURL) {
    ctaURL = selectedTemplate?.sampleCTAUrl;
  }

  const countParameters = (inputString:string) => {
    const parameterPattern = /{{\d+}}/g;
    const matches = inputString.match(parameterPattern);
    return matches?.length || 0;
  };

  const countCarouselButtonParams = (index:number) => {
    let prevParamsCount = 0;

    const { components = [] } = carouselCards[index] || {};
    const buttons =
      isEmpty(selectedTemplate.buttons) && type === "CAROUSEL"
        ? components.find((item:any) => item.type === "BUTTONS")?.buttons
        : selectedTemplate.buttons;
    buttons?.forEach((button:any) => {
      const { url = "", type } = button;
      prevParamsCount += type === "URL" ? countParameters(url) : 0;
    });

    return prevParamsCount;
  };

  const calculatePrevParamsCount = () => {
    const { format } = selectedTemplate;
    let prevParamsCount = countParameters(format) || 0;
    prevParamsCount += countCarouselButtonParams(0);

    for (let i = 0; i < carouselIndex; i++) {
      const { components = [] } = carouselCards[i] || {};
      const body = components.find((item:any) => item.type === "BODY") || {};
      prevParamsCount += body.example?.body_text[0]?.length || 0;
    }

    return prevParamsCount;
  };

  const generateButtons = (buttons:any[]) => {
    return buttons.map((button) => {
      const { type } = button;
      if (type !== "URL") return button;

      return {
        ...button,
        url: replaceParameters(button.url, parameters),
      };
    });
  };

  const createCommonPayload = () => {
    return {
      parameters,
      templateCTAUrl: ctaURL,
      buttons: selectedTemplate.buttons,
      text: type === "TEXT" ? text : "",
      caption: type !== "TEXT" ? text : "",
      actionType: selectedTemplate?.actionType,
      footerText: selectedTemplate?.footerText,
      headerText: selectedTemplate?.headerText,
      callToAction: selectedTemplate?.callToAction,
      quickReplies: selectedTemplate?.quickReplies,
      url: type === "LOCATION" ? null : media?.url,
      address: type === "LOCATION" ? media?.address : null,
      filename: type === "LOCATION" ? null : media?.filename,
      latitude: type === "LOCATION" ? media?.latitude : null,
      locationName: type === "LOCATION" ? media?.name : null,
      longitude: type === "LOCATION" ? media?.longitude : null,
    };
  };

  const createCarouselPayload = (type:string, carouselIdx?:number) => {
    if (type === "HEADER") {
      return {
        ...createCommonPayload(),
        text,
        parameters,
        caption: "",
        footerText: "",
        headerText: "",
        callToAction: [],
        actionType: "None",
        templateCTAUrl: "",
      };
    } else {
      const { carouselCards } = selectedTemplate;
      const { components = [] } = carouselCards[carouselIndex] || {};

      // Extract Components
      const headerComponent =
        components.find((component:any) => component.type === "HEADER") || {};
      const bodyComponent =
        components.find((component:any) => component.type === "BODY") || {};
      const buttonsComponent =
        components.find((component:any) => component.type === "BUTTONS") || {};

      // Extract Data
      const text = bodyComponent.text || "";
      const buttons = buttonsComponent.buttons || [];
      const prevParamsCount = calculatePrevParamsCount();
      const mediaType = headerComponent.format || "";
      const carouselParams = bodyComponent.example?.body_text[0]?.length || 0;

      const newButtons = generateButtons(buttons);

      return {
        // Confirmed
        caption: "",
        address: null,
        footerText: "",
        headerText: "",
        latitude: null,
        parameters: [],
        longitude: null,
        locationName: null,
        actionType: selectedTemplate?.actionType,

        // Doubt
        callToAction: buttons,
        templateCTAUrl: "rverv",

        // Carousel
        mediaType,
        buttons: newButtons,
        text: replaceCarouselParameters(
          text,
          parameters.slice(prevParamsCount, prevParamsCount + carouselParams)
        ),
      };
    }
  };

  const createPayload = (position:any) => {
    if (isCarousel) {
      return createCarouselPayload(position);
    } else {
      return createCommonPayload();
    }
  };

  return (
    <Box style={{ width: "100%", minWidth: "300px", maxWidth: "500px" }}>
      {/* Carousel Cards */}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          width: "100%",
          position: "relative",
          transition: "all 0.2s",
        }}
      >
        <Box
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "flex-end",
            width: "10%",
            overflow: "clip",
            padding: "8px 16px",
          }}
        >
          <Box
            style={{
              minWidth: "200px",
              opacity: 0.5,
              transform: "scaleY(0.9)",
            }}
          >
            <LeftPreviewCard
              {...props}
              carouselIndex={carouselIndex - 1}
              carouselCards={carouselCards}
              createCarouselPayload={createCarouselPayload}
            />
          </Box>
        </Box>

        <Box
          style={{
            padding: 8,
            width: "80%",
          }}
        >
          <CenterPreviewCard
            {...props}
            carouselIndex={carouselIndex}
            carouselCards={carouselCards}
            createPayload={createPayload}
            createCarouselPayload={createCarouselPayload}
          />
        </Box>

        <Box
          style={{
            marginTop: "auto",
            width: "10%",
            overflow: "clip",
            padding: "8px 16px",
          }}
        >
          <Box
            style={{
              minWidth: "200px",
              opacity: 0.5,
              transform: "scaleY(0.9)",
            }}
          >
            <RightPreviewCard
              {...props}
              carouselIndex={carouselIndex + 1}
              carouselCards={carouselCards}
              createCarouselPayload={createCarouselPayload}
            />
          </Box>
        </Box>
      </Box>

      {/* Carousel Bottom Navigation Buttons */}
      <CardButtons
        carouselIndex={carouselIndex}
        setCarouselIndex={setCarouselIndex}
        carouselCards={carouselCards}
        cards={cards}
        addCarouselTemplate={addCarouselTemplate}
      />

      {carouselCards?.length && addCarouselTemplate && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
          }}
        >
          <Button
            variant="outlined"
            startIcon={<AddOutlined />}
            style={{ padding: "0 48px" }}
            disabled={carouselCards?.length >= 10 || !addCarouselTemplate}
            onClick={() => addCarouselTemplate(carouselIndex + 1)}
          >
            Add Card
          </Button>
        </Box>
      )}
    </Box>
  );
};

const LeftPreviewCard = React.memo(function LeftPreviewCard(props:any) {
  const {
    bigIcon,
    maxHeight,
    selectedTemplate,
    createCarouselPayload,
    carouselCards,
    carouselIndex,
    cards,
  } = props;

  if (carouselIndex < 0) {
    return <></>;
  }

  return (
    <Media
      type="CAROUSEL"
      bigIcon={bigIcon}
      isHideIcon={true}
      hideMessageTopEdge={true}
      maxHeight={maxHeight}
      payload={{ ...createCarouselPayload("FOOTER", carouselIndex) }}
      carouselCards={carouselCards}
      carouselIndex={carouselIndex}
      selectedTemplate={selectedTemplate}
      cards={cards}
      key={carouselIndex}
      lineHeight={1.8}
      lines={3}
    />
  );
}, arePropsEqual);

const RightPreviewCard = React.memo(function RightPreviewCard(props:any) {
  const {
    bigIcon,
    maxHeight,
    selectedTemplate,
    createCarouselPayload,
    carouselCards,
    carouselIndex,
    cards,
  } = props;

  const carouselSize = carouselCards?.length || cards?.length || 0;
  if (carouselIndex - 1 >= carouselSize - 1) {
    return <></>;
  }

  return (
    <Media
      type="CAROUSEL"
      bigIcon={bigIcon}
      isHideIcon={true}
      hideMessageTopEdge={true}
      maxHeight={maxHeight}
      payload={{ ...createCarouselPayload("FOOTER", carouselIndex) }}
      carouselCards={carouselCards}
      carouselIndex={carouselIndex}
      selectedTemplate={selectedTemplate}
      cards={cards}
      key={carouselIndex}
      lineHeight={1.8}
      lines={3}
    />
  );
}, arePropsEqual);

const CenterPreviewCard = React.memo(function CenterPreviewCard(props:any) {
  const {
    bigIcon,
    maxHeight,
    selectedTemplate,
    createPayload,
    createCarouselPayload,
    carouselCards,
    carouselIndex,
    cards,
    isHideIcon,
    deleteCarouselTemplate,
    setCarouselIndex,
  } = props;

  const carouselLength = (cards?.length ? cards : carouselCards)?.length || 0;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Media
        bigIcon={bigIcon}
        maxHeight={maxHeight}
        isHideIcon={isHideIcon}
        payload={{ ...createPayload("HEADER") }}
        type={"TEXT"}
        showDisclaimer={false}
        disclaimerMargin={5}
        selectedTemplate={selectedTemplate}
        carouselCards={[]}
        key={"header"}
      />

      <Box style={{ minWidth: "200px", width: "100%", position: "relative" }}>
        <Media
          type="CAROUSEL"
          bigIcon={bigIcon}
          isHideIcon={true}
          hideMessageTopEdge={true}
          showCarouselButtons={true}
          maxHeight={maxHeight}
          showDisclaimer={false}
          payload={{ ...createCarouselPayload("FOOTER", carouselIndex) }}
          carouselCards={carouselCards}
          carouselIndex={carouselIndex}
          selectedTemplate={selectedTemplate}
          cards={cards}
          key={carouselIndex}
          minHeight={1.6 * 3}
        />

        {/* Left Arrow Key */}
        {carouselIndex > 0 && (
          <IconButton
            style={{
              position: "absolute",
              top: "50%",
              left: -40,
              borderRadius: "50%",
              background: "rgb(219,219,219, 0.67)",
              padding: 8,
              color: "gray",
              cursor: "pointer",
            }}
            onClick={() => setCarouselIndex(carouselIndex - 1)}
          >
            <ArrowBackIosOutlined style={{ cursor: "pointer", fontSize: 14 }} />
          </IconButton>
        )}

        {/* Right Arrow Key */}
        {carouselIndex < carouselLength - 1 && (
          <IconButton
            style={{
              position: "absolute",
              top: "50%",
              right: -40,
              borderRadius: "50%",
              background: "rgb(219,219,219, 0.67)",
              padding: 8,
              color: "gray",
              cursor: "pointer",
              zIndex: 2,
            }}
            onClick={() => setCarouselIndex(carouselIndex + 1)}
          >
            <ArrowForwardIosOutlined
              style={{ cursor: "pointer", fontSize: 14 }}
            />
          </IconButton>
        )}

        {/* Delete Button */}
        {carouselCards?.length > 1 && deleteCarouselTemplate && (
          <IconButton
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              borderRadius: "50%",
              background: "rgb(219,219,219, 0.67)",
              padding: 4,
              color: "gray",
              cursor: "pointer",
            }}
            onClick={() => deleteCarouselTemplate(carouselIndex - 1)}
          >
            <Delete style={{ fontSize: 14, padding: 0 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
});

const CardButtons = React.memo(function CardButtons(props:any) {
  const {
    carouselIndex,
    setCarouselIndex,
    carouselCards: originalCards,
    cards,
    addCarouselTemplate,
  } = props;

  const carouselCards = cards?.length ? cards : originalCards;
  const carouselLength = carouselCards?.length || 0;
  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: 48,
      }}
    >
      {carouselCards.map((_:any, index:number) => {
        return (
          <Grid item key={index} style={{ paddingBottom: 8 }}>
            <Tooltip
              interactive
              title={
                carouselLength < 10 && addCarouselTemplate ? (
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => addCarouselTemplate(index + 1)}
                  >
                    <Typography variant="h6">Add</Typography>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <IconButton
                onClick={() => setCarouselIndex(index)}
                style={{
                  padding: "0.3rem",
                  borderRadius: "50%",
                  background:
                    carouselIndex === index ? "darkGray" : "lightGray",
                }}
              />
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
}, arePropsEqual);

function arePropsEqual(oldProps:any, newProps:any) {
  return oldProps.carouselIdx === newProps.carouselIdx;
}

export default React.memo(CarouselTemplateMessagePreview);
