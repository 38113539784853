import React from 'react';

const boldPattern = /\*(\**[^*\n]+\**)\*/g;
const italicPattern = /_(_*[^_\n]+_*)_/g;
const strikePattern = /~(~*[^_\n]+~*)~/g;
const urlPattern = /(http|https)?:\/\/[^\s]+/g;
function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

function replaceBold(match: string) {
  return '<b>' + match.substring(1, match.length - 1) + '</b>';
}
function replaceItalic(match: string) {
  return '<i>' + match.substring(1, match.length - 1) + '</i>';
}
function replaceStrike(match: string) {
  return '<s>' + match.substring(1, match.length - 1) + '</s>';
}

function replaceUrl(match: string) {
  return `<a style="word-break: break-word" target="_blank" rel="noopener noreferrer" href="${match}">${match}</a>`;
}

function formatText(dangerousText: string) {
  const text = dangerousText;
  let formattedText = '';
  formattedText = text.replace(boldPattern, replaceBold);
  formattedText = formattedText.replace(italicPattern, replaceItalic);
  formattedText = formattedText.replace(strikePattern, replaceStrike);
  formattedText = formattedText.replace(urlPattern, replaceUrl);
  return formattedText;
}

export default formatText;
