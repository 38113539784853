import { Box, IconButton, TextField, Typography, Grid } from "@material-ui/core";
import { AccessTimeOutlined, CardGiftcardOutlined } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment-timezone";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { timeZones } from "../../../config/timezone";
import { Autocomplete } from "@material-ui/lab";

export default function LimitedTimeOffer(props:any) {
  const {
    limitedTimeOffer,
    formattedLimitedTimeOffer,
    buttons,
    formattedButtons,
  } = props;

  const { text } = limitedTimeOffer || {};
  const expiryDate =
    formattedLimitedTimeOffer?.parameters?.[0]?.limited_time_offer
      ?.expiration_time_ms || 0;

  const code = formattedButtons?.length
    ? formattedButtons?.find(({ sub_type }:any) =>
        ["COPY_CODE", "copy_code"].includes(sub_type)
      )?.parameters?.[0]?.coupon_code
    : buttons?.find((button:any) => button.type === "COPY_CODE")?.example;

  const isExpired = expiryDate && moment(expiryDate).isBefore(moment());

  const getExpiryPreview = (expiryDate:any) => {
    const date = moment(expiryDate);
    const now = moment();
    const days = date.diff(now, "days");
    const expiryObj = { text: "", color: "#717171" };

    if (isExpired) {
      expiryObj.text = "Offer ended";
    } else if (now.isSame(date, "day")) {
      const time = date.format("h:mm A");
      expiryObj.text = `Ends today at ${time}`;
      expiryObj.color = "#C41E3A";
    } else if (days < 15) {
      expiryObj.text = `Ends in ${days} days`;
    } else {
      const day = date.format("D MMM");
      expiryObj.text = `Offer ends on ${day}`;
    }

    return expiryObj;
  };

  const { text: expiryText, color: expiryColor } = getExpiryPreview(expiryDate);

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        background: "#F8F8F8",
        padding: 8,
        borderRadius: "0  0 4px 4px",
      }}
    >
      {/* Preview Gift icon */}
      <Box>
        <IconButton
          style={{ background: isExpired ? "#DCDCDC" : "#D1F0E5", padding: 10 }}
        >
          <CardGiftcardOutlined
            style={{ color: isExpired ? "#A0A0A0" : "#119F88" }}
          />
        </IconButton>
      </Box>

      {/* Preview Text & Code */}
      <Box
        maxWidth="calc(100% - 100px)"
        style={{
          paddingLeft: 8,
          alignItems: "center",
        }}
      >
        <Typography variant="body2">{text}</Typography>
        {/* <Box mt={1} /> */}

        {!!expiryDate && (
          <Typography
            variant="body2"
            style={{
              whiteSpace: "nowrap",
              color: expiryColor,
              lineHeight: "12px",
            }}
          >
            {expiryText}
          </Typography>
        )}

        <Box display="flex" style={{ color: "#717171", lineHeight: "12px" }}>
          <Typography
            variant="body2"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {`Code: ${code}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function LimitedTimeOfferExpirationInput(props:any) {
  const {
    classes,
    limitedTimeOffer,
    setLimitedTimeOffer,
    assistantTimezone,
  } = props;
  const defaultTime =
    limitedTimeOffer?.parameters?.[0]?.limited_time_offer?.expiration_time_ms;
  const [date, setDate] = React.useState(moment(defaultTime));
  const [timezone, setTimezone] = React.useState(
    assistantTimezone || "Asia/Calcutta"
  );

  const setTimestamp = (inputDate:any, tz?:any) => {
    const cloneLto = JSON.parse(JSON.stringify(limitedTimeOffer));
    const timestamp = moment(inputDate || date)
      .tz(tz || timezone, true)
      .valueOf();
    cloneLto.parameters[0].limited_time_offer.expiration_time_ms = timestamp;
    setLimitedTimeOffer(cloneLto);
  };

  return (
    <>
      <Box className={classes.datePicker} style={{ paddingLeft: "2px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: "#000000", marginTop: "16px" }}
            >
              Select Date
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              paragraph
              style={{ marginBottom: 4 }}
            >
              Select copuon expiry date
            </Typography>
            <KeyboardDatePicker
              style={{
                marginTop: "0px",
                width: "100%",
                marginBottom: "30px",
              }}
              className={classes.paddingControl}
              disableToolbar
              minDate={new Date()}
              maxDate={new Date(new Date().setDate(new Date().getDate() + 60))}
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={date}
              onChange={(date:any) => {
                setDate(date);
                setTimestamp(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom style={{ color: "#000000" }}>
              Select Time
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              paragraph
              style={{ marginBottom: 4 }}
            >
              Select coupon expiry time
            </Typography>
            <KeyboardTimePicker
              className={classes.paddingControl}
              style={{
                marginTop: "0px",
                width: "100%",
                marginBottom: "30px",
              }}
              margin="normal"
              id="time-picker"
              value={date}
              onChange={(date:any) => {
                setDate(date);
                setTimestamp(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              keyboardIcon={<AccessTimeOutlined />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom style={{ color: "#000000" }}>
              Select Timezone
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              paragraph
              style={{ marginBottom: 4 }}
            >
              Select timezone for Expiry date & time
            </Typography>
            <Autocomplete
              className={classes.timeZoneClass}
              disableClearable
              style={{ paddingLeft: 0 }}
              options={timeZones}
              defaultValue={{
                label: "Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)",
                value: "+05:30",
              }}
              onChange={(e, value:any) => {
                const tz = value?.label
                  ?.split(" (")
                  ?.pop()
                  .slice(0, -1);
                setTimezone(tz);
                setTimestamp(date, tz);
              }}
              getOptionLabel={(option) => {
                const label = option?.label?.split(" (")?.pop() || "";
                return (
                  `${label.substring(0, label.length - 1)} (${option.value})` ||
                  ""
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    height: "42px",
                    width: "100%",
                    paddingTop: "unset",
                    paddingRight: 2,
                  }}
                  variant="outlined"
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
    </>
  );
}
