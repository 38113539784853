import React from "react"

export enum entityType {
  LINK = "LINK",
  BTN = "BTN"
}
export enum variantType {
  btn = "WhatsAppBtn",
  btnWithWidget = "WhatsAppBtnAndChatWidget"
}

export enum widgetSessionWindowType {
  always = "ALWAYS",
  hour24 = "24_HOUR",
  week1 = "1_WEEK"
}

export type personalizedUrl = {
  url: string,
  urlMsg: string,
  userMsg: string,
  regEx: string,
  toFormat: boolean,
  toCapitalize: boolean
}
export type whatsAppBtn = {
  variant: string,
  btnBackground: string,
  btnCTA: string,
  mb: string,
  ml: string,
  mr: string,
  borderRadius: string,
  position: string,
  brandName: string,
  brandSub: string,
  brandColor: string,
  brandImgUrl: string,
  widgetBtnCTA: string,
  openWidgetByDefault: string,
  openWidgetSessionWindow: widgetSessionWindowType,
  onscreenMsg: string,
  widgetOnMobile: string,
  urlPersonalization: boolean,
  personalizedUrls: personalizedUrl[]
}

export interface ILink {
  entityType: entityType,
  linkId: string,
  phoneNumber: string,
  prefilledMsg: string,
  clientId: string,
  assistantId: string
  whatsappBtnSettings: whatsAppBtn | null,
}

export interface faq {
  question: string;
  answer: React.ReactNode;
}