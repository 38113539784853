export const replaceParameters = (text: string, parameters: any[]) => {
  let formattedText = text || '';

  (parameters || []).forEach((param, index) => {
    const pattern = new RegExp(`\\{\\{${index + 1}\\}\\}`);
    if (param) {
      formattedText = formattedText.replace(pattern, `[${param}]`);
    }
  });
  return formattedText;
};

export function replaceParametersOpt(text: string, parameters: any[]) {
  let formattedText = text || '';

  (parameters || []).forEach((param, index) => {
    const pattern = new RegExp(`\\{\\{${index + 1}\\}\\}`);
    if (param) {
      formattedText = formattedText.replace(pattern, `${param}`);
    }
  });
  return formattedText;
}

export function replaceCarouselParameters(text: string, parameters: any[]) {
  let formattedText = text || '';

  (parameters || []).forEach((param, i) => {
    const pattern = new RegExp(`\\{\\{${i + 1}\\}\\}`);
    if (param) {
      formattedText = formattedText.replace(pattern, `[${param}]`);
    }
  });

  return formattedText;
}
