import React, { Component } from 'react';
import { Box, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';

class BenefitsCard extends Component<Props> {
    render(): React.ReactElement {
        const {classes} = this.props
        return (
            <Box mx={{xs: 2, md: 0}} py={{xs:2, md: 4}} px={{xs: 2, md:4}} className={classes.guideContainer}>
                <Typography variant="h3" color="primary" paragraph style={{ fontWeight: "bold" }}>
                    Benefits of sharing WhatsApp Link
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            Connect with users directly on WhatsApp with ease.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            Get the user&rsquo;s name & number added to your Contact List instantly when they send a WhatsApp message. If you&rsquo;re using AiSensy, their name and number will be saved directly to your AiSensy CRM.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            Get people engaged in 1:1 Real-time conversations, and provide customer support on WhatsApp.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            Encourage users to save your contact, allowing for continued engagement.
                        </Typography>
                    </li>
                </ul>
            </Box>
        );
    }
}

interface Props extends WithStyles<typeof styles> {}

const styles = () => createStyles({
	guideContainer: {
    background: "white",
    borderRadius: "8px",
		backgroundColor: "rgb(248, 248, 248)"
  },
})

export default withStyles(styles)(BenefitsCard);