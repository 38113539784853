import React from 'react';
import { FilterNoneOutlined, OpenInNew, Phone } from '@material-ui/icons';
import { withStyles, Tooltip, Typography, Theme , Box} from '@material-ui/core';
import { replaceParameters } from '../../../helpers/replaceParameters';

import templateMsgHelper from '../../../helpers/templateMsgHelper';

const InteractiveAction = (props: any) => {
  const { type, payload, classes, theme } = props;
  let isQuickReply = false;
  let list: any = [];

  const parseNewButtonsToInteractiveFormat = () => {
    const { buttons, formattedButtons } = payload;
    list = [];
    for (let index = 0; index < buttons?.length; index++) {
      const button = buttons[index];
      const { type, text, phone_number: number, example } = button;
      let url = button.urlMetadata?.url || button.url;

      if (url) {
        if (formattedButtons?.length) {
          const urlParameters = formattedButtons
            ?.find(
              (c: any) =>
                ['button', 'BUTTON'].includes(c.type) &&
                ['url', 'URL'].includes(c.sub_type) &&
                c.index == index,
            )
            ?.parameters?.map(({ text }: any) => text);
          const formattedURL =
            replaceParameters(url, urlParameters) ||
            button.actualUrl ||
            button?.example?.[0];
          if (formattedURL) url = formattedURL;
        }

        list.push(text + ',' + url);
      } else if (number) {
        list.push(text + ',' + number);
      } else if (['COPY_CODE'].includes(type)) {
        const copyCodeParams = formattedButtons
          ?.find((c: any) => c.index === index)
          ?.parameters?.map((e: any) => e[e.type]);
        list.push('Copy Code,' + (copyCodeParams?.[0] || example));
      } else {
        list.push(text);
      }
    }
  };

  if (payload?.actionType === 'QuickReplies') {
    list = payload.quickReplies;
  } else if (type === 'TEXT') {
    list = (payload.text || '')
      .split(' | [')
      .map((i: string) => i.trim().substring(0, i.length - 1));
    list.shift();
  } else if (type === 'QUICK_REPLY_CARD') {
    isQuickReply = true;
    list = (payload.options || '').map(
      (i: any, index: number) => i.title || `Button_${index}`,
    );
  } else if (['IMAGE', 'VIDEO', 'FILE', 'LOCATION'].includes(type)) {
    list = (payload.caption || '')
      .split(' | [')
      .map((i: string) => i.trim().substring(0, i.length - 1));
    list.shift();
  }

  if (payload?.formattedButtons?.length || payload?.buttons?.length) {
    parseNewButtonsToInteractiveFormat();
  }

  return (
    <Box>
      {list.map((item: any, index: number) => {
        let type = 'text';
        let action = '';
        const validNumberPattern = /^\+*\d+$/;
        let text = item?.trim() || '';
        const parts = text.split(',');
        if (!isQuickReply && parts.length > 1) {
          const lastBlock = parts.pop();
          text = parts.join(',');
          if (templateMsgHelper.validateUrl(lastBlock.trim())) {
            type = 'url';
            action = lastBlock.replace(/\[([^\]]+)\]/g, (match: string) =>
              match.substring(1, match.length - 1),
            );
          } else if (validNumberPattern.test(lastBlock.trim())) {
            type = 'phone';
            action = lastBlock;
          } else {
            if (parts?.[0] === 'Copy Code') {
              type = 'copy_code';
              action = lastBlock;
            } else {
              text = [...parts, lastBlock].join(',');
            }
          }
        }

        if (!text) return null;

        if (type === 'text') {
          return (
            <Box
              key={index}
              className={`defaultTheme ${classes.fullWidth} ${
                theme === 1 ? classes.theme1 : classes.theme2
              }`}
            >
              {item?.text || text}
            </Box>
          );
        }

        if (type === 'phone') {
          return (
            <Tooltip
              arrow
              title={item?.number || action || ''}
              classes={{
                arrow: classes.customArrow,
                tooltip: classes.customTooltip,
              }}
            >
              <a href={`tel:${item?.number || action}`}>
                <Box
                  key={index}
                  display="flex"
                  style={{ alignItems: 'center' }}
                  className={`defaultTheme ${classes.fullWidth}  ${
                    theme === 1 ? classes.theme1 : classes.theme2
                  }`}
                >
                  <Phone
                    className={classes.actionIcon}
                    style={{ marginBottom: 2 }}
                  />
                  <Box className={classes.actionText}>{item?.text || text}</Box>
                </Box>
              </a>
            </Tooltip>
          );
        }

        if (type === 'copy_code') {
          return (
            <Tooltip
              arrow
              title={item?.example || action || ''}
              classes={{
                arrow: classes.customArrow,
                tooltip: classes.customTooltip,
              }}
            >
              <Box
                key={index}
                display="flex"
                style={{ alignItems: 'center' }}
                className={`defaultTheme ${classes.fullWidth}  ${
                  theme === 1 ? classes.theme1 : classes.theme2
                }`}
                onClick={() => {
                  navigator.clipboard.writeText(item?.example || action || '');
                }}
              >
                <FilterNoneOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    fontSize: 16,
                    marginBottom: 2,
                  }}
                  className={classes.actionIcon}
                />
                <Box className={classes.actionText}>Copy Code</Box>
              </Box>
            </Tooltip>
          );
        }

        return (
          <a
          key={index}
            target="_blank"
            rel="noreferrer"
            href={
              item?.url ||
              payload?.templateCTAUrl?.replace(/[\[\]']+/g, '') ||
              action ||
              ''
            }
          >
            <Box
              key={index}
              display="flex"
              style={{ alignItems: 'center' }}
              className={`defaultTheme ${classes.fullWidth} ${
                theme === 1 ? classes.theme1 : classes.theme2
              }`}
            >
              <OpenInNew
                className={classes.actionIcon}
                style={{ marginBottom: 2 }}
              />
              <Box className={classes.actionText}>{text}</Box>
              {(item?.url ||
                payload?.templateCTAUrl?.replace(/[\[\]']+/g, '')) && (
                <Box className={classes.arrowTop}>
                  <Typography className={classes.ctaURL}>
                    {item?.url ||
                      payload?.templateCTAUrl?.replace(/[\[\]']+/g, '')}
                  </Typography>
                </Box>
              )}
            </Box>
          </a>
        );
      })}
    </Box>
  );
};

const styles: any = (theme: Theme) => ({
  actionIcon: {
    fontSize: '16px',
    margin: '2px 4px -2px',
    color: '#4c9edc',
  },

  theme1: {
    background: 'white',
  },

  theme2: {
    background: 'white',
  },

  fullWidth: {
    width: '100%',
    borderRadius: '15px',
    margin: '0.5em 0.5em 0.5em 0',
    boxSizing: 'border-box',
    padding: '4px 8px 6px',
    textAlign: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#4c9edc',
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: '500',
  },

  actionText: {
    maxWidth: '210px',
    overflow: 'hidden',
    whiteSpace: 'prewrap',
    textOverflow: 'ellipsis',
    color: '#4c9edc',
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: '500',
  },

  arrowTop: {
    marginTop: '20px',
    padding: '8px 12px',
    boxSizing: 'border-box',
    lineBreak: 'anywhere',
    background: '#edeaea',
    color: '#0000009c',
    borderRadius: '6px',
    textAlign: 'center',
    position: 'relative',
    fontSize: '14px',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 'calc(50%  - 15px)',
      top: '-12px',
      borderTop: 'none',
      borderRight: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderBottom: '15px solid #edeaea',
    },
  },

  ctaURL: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    maxHeight: '60px',
    overflow: 'hidden',
  },

  actionBox: {
    padding: '10px 4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  customTooltip: {
    backgroundColor: 'rgb(105,105,105)',
    color: 'white',
    boxShadow: 'none',
    fontSize: '14px',
    zIndex: 100,
  },

  customArrow: {
    color: 'rgb(105,105,105)',
  },
});

export default withStyles(styles)(InteractiveAction);
