import React from "react";
import { isEmpty, set } from "lodash";
import { Box } from "@material-ui/core";

import Media from "./Media";
import {
  replaceParameters,
  replaceCarouselParameters,
} from "../../helpers/replaceParameters";
import CarouselTemplateMessagePreview from "../Chatbox/CarouselTemplateMessagePreview";

const TemplateMessagePreview = (props:any) => {
  const {
    media,
    bigIcon,
    maxHeight,
    parameters,
    showDisclaimer,
    selectedTemplate,

    // Carousel Props
    setCarouselIndex,
    carouselIndex = 0,
    addCarouselTemplate,
    deleteCarouselTemplate,
    cards,
    formattedButtons,
    paymentParams,
    formattedLimitedTimeOffer
  } = props;

  const type = selectedTemplate?.type;
  let ctaURL = selectedTemplate?.templateCTAUrl;
  const text = replaceParameters(
    selectedTemplate?.format || selectedTemplate?.payload?.text,
    parameters
  );

  const isCarousel = type === "CAROUSEL";
  let carouselCards:any = [];
  if (selectedTemplate) {
    carouselCards = selectedTemplate.carouselCards;
  }

  // For pre-existing templates
  if (!ctaURL) {
    const urlObject = (selectedTemplate?.callToAction || []).filter(
      (e:any) => e.type === "URL"
    );

    if (urlObject.length > 0) {
      ctaURL = urlObject[0].buttonValue;
      if (parameters && parameters.length > 0) {
        const param = parameters[parameters.length - 1];
        if (param)
          ctaURL = ((string, replacement) => {
            return string.replace(/{{(\d+)}}/g, function(match:any, g1:any) {
              return `[${replacement}]`;
            });
          })(urlObject[0].buttonValue, param);
      }
    }
  }

  if (selectedTemplate?.sampleCTAUrl && !ctaURL) {
    ctaURL = selectedTemplate?.sampleCTAUrl;
  }

  const countParameters = (inputString: string) => {
    const parameterPattern = /{{\d+}}/g;
    const matches = inputString.match(parameterPattern);
    return matches?.length || 0;
  };

  const countCarouselButtonParams = (index:number) => {
    let prevParamsCount = 0;

    const { components = [] } = carouselCards[index] || {};
    const buttons =
      isEmpty(selectedTemplate.buttons) && type === "CAROUSEL"
        ? components.find((item:any) => item.type === "BUTTONS")?.buttons
        : selectedTemplate.buttons;
    buttons?.forEach((button:any) => {
      const { url = "", type } = button;
      prevParamsCount += type === "URL" ? countParameters(url) : 0;
    });

    return prevParamsCount;
  };

  const calculatePrevParamsCount = () => {
    const { format } = selectedTemplate;
    let prevParamsCount = countParameters(format) || 0;
    prevParamsCount += countCarouselButtonParams(0);

    for (let i = 0; i < carouselIndex; i++) {
      const { components = [] } = carouselCards[i] || {};
      const body = components.find((item:any) => item.type === "BODY") || {};
      prevParamsCount += body.example?.body_text[0]?.length || 0;
    }

    return prevParamsCount;
  };

  const generateButtons = (buttons:any[]) => {
    return buttons.map((button:any) => {
      const { type } = button;
      if (type !== "URL") return button;

      return {
        ...button,
        url: replaceParameters(button.url, parameters),
      };
    });
  };

  const createCommonPayload = () => {
    return {
      parameters,
      templateCTAUrl: ctaURL,
      buttons: selectedTemplate?.buttons || [],
      text: type === "TEXT" ? text : "",
      caption: type !== "TEXT" ? text : "",
      actionType: selectedTemplate?.actionType,
      footerText: selectedTemplate?.footerText,
      headerText: selectedTemplate?.headerText,
      headerType: selectedTemplate?.headerType,
      callToAction: selectedTemplate?.callToAction,
      quickReplies: selectedTemplate?.quickReplies,
      url: type === "LOCATION" ? null : media?.url,
      address: type === "LOCATION" ? media?.address : null,
      filename: type === "LOCATION" ? null : media?.filename,
      latitude: type === "LOCATION" ? media?.latitude : null,
      locationName: type === "LOCATION" ? media?.name : null,
      longitude: type === "LOCATION" ? media?.longitude : null,
    };
  };

  const createCarouselPayload = (type:string) => {
    if (type === "HEADER") {
      return {
        ...createCommonPayload(),
        text,
        parameters,
        caption: "",
        footerText: "",
        headerText: "",
        callToAction: [],
        actionType: "None",
        templateCTAUrl: "",
      };
    } else {
      const { carouselCards } = selectedTemplate;
      const { components = [] } = carouselCards[carouselIndex] || {};

      // Extract Components
      const headerComponent =
        components.find((component:any) => component.type === "HEADER") || {};
      const bodyComponent =
        components.find((component:any) => component.type === "BODY") || {};
      const buttonsComponent =
        components.find((component:any) => component.type === "BUTTONS") || {};

      // Extract Data
      const text = bodyComponent.text || "";
      const buttons = buttonsComponent.buttons || [];
      const prevParamsCount = calculatePrevParamsCount();
      const mediaType = headerComponent.format || "";
      const carouselParams = bodyComponent.example?.body_text[0]?.length || 0;

      const newButtons = generateButtons(buttons);

      return {
        // Confirmed
        caption: "",
        address: null,
        footerText: "",
        headerText: "",
        latitude: null,
        parameters: [],
        longitude: null,
        locationName: null,
        actionType: selectedTemplate?.actionType,

        // Doubt
        callToAction: buttons,
        templateCTAUrl: "rverv",

        // Carousel
        mediaType,
        buttons: newButtons,
        text: replaceCarouselParameters(
          text,
          parameters.slice(prevParamsCount, prevParamsCount + carouselParams)
        ),
      };
    }
  };

  const createPayload = (position:any) => {
    if (isCarousel) {
      return createCarouselPayload(position);
    } else {
      return createCommonPayload();
    }
  };

  const PreviewComponent = () => {
    if (type === "CAROUSEL") {
      return (
        <CarouselTemplateMessagePreview
          media={media}
          bigIcon={bigIcon}
          maxHeight={maxHeight}
          parameters={parameters}
          showDisclaimer={showDisclaimer}
          selectedTemplate={selectedTemplate}
          // Carousel Props
          setCarouselIndex={setCarouselIndex}
          carouselIndex={carouselIndex}
          addCarouselTemplate={addCarouselTemplate}
          deleteCarouselTemplate={deleteCarouselTemplate}
          cards={cards}
          formattedButtons={formattedButtons}
        />
      );
    }

    return (
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Media
            bigIcon={bigIcon}
            maxHeight={maxHeight}
            isHideIcon={props.isHideIcon}
            payload={{ ...createPayload("HEADER") }}
            type={isCarousel ? "TEXT" : selectedTemplate?.type}
            showDisclaimer={isCarousel ? false : showDisclaimer}
            selectedTemplate={selectedTemplate}
            // Carousel Props
            carouselCards={[]}
            formattedButtons={formattedButtons}
            paymentParams={paymentParams}
            formattedLimitedTimeOffer={formattedLimitedTimeOffer}
          />
        </Box>
      </Box>
    );
  };

  return <PreviewComponent />;
};

export default React.memo(TemplateMessagePreview, (oldProps, newProps) => {
  const arePropsEqual = JSON.stringify(oldProps) === JSON.stringify(newProps);
  return arePropsEqual;
});
