import React, { useEffect, useRef, useState } from 'react';
import { createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Theme,
  Paper,
  RadioGroup,
  Radio,
  useMediaQuery,
  Snackbar,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import TemplateMessagePreview from '../../commons/TemplateMessagePreview/TemplateMessagePreview';
import { Alert, Autocomplete } from '@material-ui/lab';
import LANGUAGES from '../../config/languages';
import axios from 'axios';
import { URL } from '../../config/config';
import {
  AddCircle,
  CheckCircle,
  EmojiEmotions,
  ExitToAppOutlined,
  FileCopyOutlined,
  HourglassEmptySharp,
  MenuBook,
  RadioButtonChecked,
  RefreshOutlined,
  Reply,
  SentimentSatisfied,
  SentimentVerySatisfied,
  TouchApp,
} from '@material-ui/icons';
import { validateUrl } from '../../helpers/url';

function AiTemplatePrompt(props: {
  classes: any;
  category?: string;
  language?: string;
}) {
  const { category, language, classes } = props;
  const [userName, setUserName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [optimisationObjective, setOptimisationObjective] = useState(
    'Click Rate',
  );
  const [includeEmojis, setIncludeEmojis] = useState(true);
  const [messageMood, setMessageMood] = useState('Normal');
  const [aiTemplate, setAiTemplate] = useState({
    ...sampleAiTemplate,
    category: category || 'Marketing',
    language: language || 'English',
  });
  const [snackbar, setSnackbar] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });
  const templatePreviewRef: any = useRef(null);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const getButtonsFromFormat = (format: string) => {
    const formatArr = format.split(' | ');
    let actionType = 'CAT';
    const callToAction: any = [];
    const quickReplies: any = [];
    debugger;
    for (let i = formatArr.length - 1; i >= 0; i--) {
      const text = formatArr[i].replace(/['"]+/g, '');
      if (text) {
        const len = text.length;
        if (text[0] !== '[' || text[len - 1] !== ']') break;

        const splitResults: any = text.substring(1, len - 1).split(',');
        const buttonMetadata = {
          isCta: false,
          ctaType: '',
          title: splitResults[0],
          value: '',
        };

        for (let idx = 1; idx < splitResults.length; idx++) {
          if (!splitResults[idx].length) continue;
          buttonMetadata.value = splitResults[idx];
          const part = splitResults[idx].trim();

          const isUrl = validateUrl(part);
          if (isUrl) {
            buttonMetadata.isCta = true;
            buttonMetadata.ctaType = 'URL';
            break;
          }

          const regex = new RegExp(
            /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/gim,
          );
          const isPhone = regex.test(part);
          if (isPhone) {
            buttonMetadata.isCta = true;
            buttonMetadata.ctaType = 'Phone Number';
            break;
          }

          buttonMetadata.title += ` ${part}`;
        }

        const buttonValue = buttonMetadata.value.trim();
        const buttonTitle = buttonMetadata.title.trim();
        if (buttonMetadata.isCta) {
          callToAction.push({
            buttonValue,
            buttonTitle,
            type: buttonMetadata.ctaType,
          });
          actionType = 'CAT';
        } else {
          quickReplies.push(buttonTitle);
          actionType = 'QuickReplies';
        }
      }
    }

    return { callToAction, quickReplies, actionType };
  };

  const handleSubmit = async () => {
    if (!prompt) {
      return setSnackbar({
        show: true,
        severity: 'error',
        msg: 'Please enter a prompt to generate...',
      });
    }
    setLoading(true);
    try {
      const response: any = await fetch(`${URL}/generate-ai-template`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName,
          templateCategory: category || 'Marketing',
          templateLanguage: language || 'English',
          prompt,
          optimizationChoice: optimisationObjective,
          mood: messageMood === 'Normal' ? 'None' : messageMood,
          style: 'None',
          includeEmojis,
        }),
      });

      handleScroll();
      const reader: any = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let output = '';
      while (true) {
        const chunk = await reader?.read();
        const { done, value } = chunk;
        if (done) break;
        const decodedValue = decoder.decode(value);
        output += decodedValue;
        if (output.length >= 15) {
          setAiTemplate((prev) => ({
            ...prev,
            format: output,
            buttons: [],
            callToAction: [],
            quickReplies: [],
            actionType: 'CAT',
          }));
        }
      }

      const { callToAction, quickReplies, actionType } = getButtonsFromFormat(
        output,
      );
      setAiTemplate((prev) => ({
        ...prev,
        format: output,
        buttons: [],
        callToAction,
        quickReplies,
        actionType,
      }));
      setLoading(false);
      setRegenerate(true);
    } catch (error) {
      setAiTemplate((prev) => ({
        ...prev,
        format: 'Unable to generation template message. Please try again.',
        buttons: [],
        callToAction: [],
        quickReplies: [],
        actionType: 'CAT',
      }));
      setLoading(false);
      setRegenerate(true);
    }
    // setShowActionButtons(true);

    // updateAiTemplate();
  };

  useEffect(() => {
    setRegenerate(false);
  }, [prompt]);

  const handleScroll = () => {
    if (isXs && templatePreviewRef.current) {
      templatePreviewRef.current.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => window.scrollBy(0, -24), 500);
    }
  };

  return (
    <Box className={classes.root} px={{ xs: 2 }} py={6}>
      <Grid container justify="center">
        <Grid
          item
          md={10}
          lg={7}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid container>
            {/* L.H.S */}
            <Grid item xs={12} md={6}>
              <Typography variant="h3" className={classes.title}>
                Craft beautiful templates with{' '}
                <span className={classes.gradientText}>AI ✧</span>
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Optimised for higher read rates and conversion rates
              </Typography>

              {/* <Grid container spacing={2}>
              <TemplateOptions
                classes={classes}
                fieldTitle="Category"
                title={'category'}
                placeholder={'Select Template Category'}
                value={aiTemplate.category}
                options={TEMPLATE_CATEGORIES}
                updateTemplateFields={updateTemplateFields}
              />
              <TemplateOptions
                classes={classes}
                fieldTitle="Language"
                title={'language'}
                placeholder={'Select Template Language'}
                value={aiTemplate.language}
                options={LANGUAGES}
                updateTemplateFields={updateTemplateFields}
              />
            </Grid> */}

              {/* <UserInputFields
                classes={classes}
                userName={userName}
                setUserName={setUserName}
              /> */}

              {/* <TemplateObjectives
                classes={classes}
                optimisationObjective={optimisationObjective}
                handleChange={setOptimisationObjective}
              /> */}

              {/* <RadioButtonBox
                classes={classes}
                title={'Optimise for'}
                options={['Click Rate', 'Reply Rate']}
                selectedValue={optimisationObjective}
                icons={optimisationIcons}
                handleChange={setOptimisationObjective}
              />

              <RadioButtonBox
                classes={classes}
                title={'Choose your message mood'}
                options={['Normal', 'Poetic', 'Exciting', 'Funny']}
                selectedValue={messageMood}
                icons={moodIcons}
                handleChange={setMessageMood}
              /> */}

              <AdvancedOptions
                classes={classes}
                title={'Optimise for'}
                options={['Click Rate', 'Reply Rate']}
                selectedValue={optimisationObjective}
                icons={optimisationIcons}
                handleChange={setOptimisationObjective}
              />

              <AdvancedOptions
                classes={classes}
                title={'Choose your message mood'}
                options={['Normal', 'Poetic', 'Exciting', 'Funny']}
                selectedValue={messageMood}
                icons={moodIcons}
                handleChange={setMessageMood}
              />

              <PromptBar
                classes={classes}
                prompt={prompt}
                setPrompt={setPrompt}
                loading={loading}
                handlePromptSubmit={handleSubmit}
                regenerate={regenerate}
              />

              {/* <FormControlLabel
              style={{ marginTop: 8 }}
              control={
                <Checkbox
                  checked={includeEmojis}
                  onChange={(e) => setIncludeEmojis(e.target.checked)}
                  name="includeEmojis"
                  color="primary"
                  style={{ margin: '0px 8px', padding: 0 }}
                />
              }
              label="Include emojis 😊"
            /> */}

              {/* <QuickTextPrompt classes={classes} setPrompt={setPrompt} /> */}
            </Grid>
            {/* R.H.S */}
            <Grid
              item
              xs={12}
              md={6}
              ref={templatePreviewRef}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                padding: 0,
                marginTop: isXs ? 40 : 0,
              }}
            >
              <TemplateMessagePreview
                // media={aiMedia}
                parameters={[]}
                selectedTemplate={aiTemplate}
                showDisclaimer={false}
              />

              {/* {regenerate && (
                <Box style={{ display: 'flex' }}>
                  <IconButton
                    size="small"
                    className={classes.actionBtn2}
                    onClick={() =>
                      navigator.clipboard.writeText(aiTemplate.format)
                    }
                    disabled={loading}
                  >
                    <FileCopyOutlined style={{ fontSize: 16 }} />
                  </IconButton>
                  <Box mx={1} />
                  <IconButton
                    size="small"
                    className={classes.actionBtn2}
                    onClick={() =>
                      window.open(
                        'https://www.app.aisensy.com/signup',
                        '_blank',
                      )
                    }
                    disabled={loading}
                  >
                    <ExitToAppOutlined style={{ fontSize: 16 }} />
                  </IconButton>
                </Box>
              )} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.show}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setSnackbar({ show: false } as any)}
        autoHideDuration={3000}
      >
        <Alert
          severity={snackbar.severity as any}
          onClose={() => setSnackbar({ show: false } as any)}
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const RadioButtonBox = (props: any) => {
  const { classes, title, options, selectedValue, handleChange, icons } = props;
  return (
    <Box className={classes.radioBoxWrapper}>
      <SubTitle title={title} />
      <Grid container>
        {options.map((option: string, index: number) => {
          const IconComponent = icons[index];
          const isSelected = option === selectedValue;
          const color = colors[index % 4];
          const md = title === 'Optimise for' ? 3 : 2;
          return (
            <Grid
              item
              xs={6}
              md={md}
              className={classes.radioBox}
              key={option}
              onClick={() => handleChange(option)}
              style={{
                border: isSelected ? `1px solid ${color}` : '',
                backgroundColor: !isSelected ? `${color}` : '#fff',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 4,
              }}
            >
              <IconComponent
                className={classes.icon}
                style={{
                  color: isSelected ? color : '#fff',
                  fontSize: 14,
                  paddingLeft: 4,
                }}
              />
              <Typography
                variant="body1"
                style={{ color: isSelected ? color : '#fff', paddingLeft: 4 }}
              >
                {option}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const UserInputFields = (props: any) => {
  const { userName, setUserName } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <SubTitle title="Brand Name" />
        <TextField
          fullWidth
          type="text"
          placeholder="Enter your brand name here"
          value={userName}
          name="url"
          onChange={(e) => setUserName(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const TemplateOptions = (props: any) => {
  const {
    classes,
    fieldTitle,
    title,
    value,
    options,
    updateTemplateFields,
    placeholder,
  } = props;

  return (
    <Grid item>
      <SubTitle title={fieldTitle} />
      <Autocomplete
        disableClearable
        value={value}
        options={options.map((option: string) => option)}
        onChange={(e, selectedValue) => {
          updateTemplateFields(title, selectedValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.templateOptionTextfield}
            InputProps={{ ...params.InputProps }}
            placeholder={placeholder}
            name="templatOption"
          />
        )}
      />
    </Grid>
  );
};

const PromptBar = (props: any) => {
  const { classes, prompt, setPrompt, loading, handlePromptSubmit } = props;
  return (
    <Box className={classes.promptBox}>
      <SubTitle title="Enter your prompt for template" />
      {SAMPLE_PROMPTS.map((sample) => (
        <div
          key={sample}
          onClick={() => {
            setPrompt(sample);
            navigator.clipboard.writeText(sample);
          }}
          className={`${classes.container} ${classes.containerHover}`}
          style={{ marginBottom: 8 }}
        >
          <div
            className={classes.textButton}
            style={{
              border: '1px solid #dcdcdc',
              backgroundColor: '#fff',
            }}
          >
            {sample}
          </div>
          <div className={classes.overlay}>
            <IconButton aria-label="copy">
              <FileCopyOutlined className={classes.overlayIcon} />
            </IconButton>
          </div>
        </div>
      ))}

      <TextField
        fullWidth
        type="text"
        placeholder="Type your prompt here (What does your brand do & what do you wish to promote)..."
        value={prompt}
        name="url"
        multiline
        rows={6}
        style={{ marginTop: 8 }}
        onChange={(e) => setPrompt(e.target.value)}
      />

      {/* <div className={classes.promptBar}>
        <TextField
          placeholder="Enter your template message prompt here..."
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          variant="outlined"
          className={classes.textField}
          rows={3}
          multiline
        />
         <IconButton
        className={`${classes.iconButton} ${
          loading ? classes.iconButtonLoading : ''
        }`}
        onClick={handlePromptSubmit}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={16} className={classes.spinner} />
        ) : !props.regenerate ? (
          <SendIcon style={{ fontSize: 16 }} />
        ) : (
          <RefreshOutlined style={{ fontSize: 16 }} />
        )}
      </IconButton> 
      </div> */}

      <AiTemplateActions
        classes={classes}
        loading={loading}
        handlePromptSubmit={handlePromptSubmit}
        regenerate={props.regenerate}
      />
    </Box>
  );
};

const AdvancedPromptDropdownOption = (props: any) => {
  const {
    classes,
    configKey,
    title,
    options,
    onOptionclick,
    anchorEl,
    setAnchorEl,
  } = props;

  return (
    <Grid item>
      <div
        className={classes.customDropdown}
        onClick={(event) =>
          setAnchorEl(configKey, event.currentTarget, 'anchor')
        }
      >
        {title} <ArrowDropDownIcon />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((option: string) => (
          <MenuItem
            key={option}
            onClick={() => setAnchorEl(configKey, option, 'value')}
            style={{ color: 'green' }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

const QuickTextPrompt = (props: any) => {
  const { classes, setPrompt } = props;
  return (
    <Box className={classes.quickTextPrompt}>
      {/* <Typography>Sample prompts</Typography> */}
      <SubTitle title="Sample prompts" />
      <div className={classes.textButtonRow}>
        {SAMPLE_PROMPTS.map((prompt) => {
          return (
            <div
              key={prompt}
              onClick={() => {
                setPrompt(prompt);
                navigator.clipboard.writeText(prompt);
              }}
              className={`${classes.container}`}
            >
              <div className={classes.textButton}>{prompt}</div>

              <div className={classes.overlay}>
                <IconButton aria-label="copy">
                  <FileCopyOutlined className={classes.overlayIcon} />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

const TemplateObjectives = (props: any) => {
  const { classes, optimisationObjective, handleChange } = props;
  const objectives = ['Click Rate', 'Reply Rate'];
  return (
    <Box className={classes.templateObjectives}>
      <SubTitle title="Optimise for" />
      <Grid container spacing={1}>
        <RadioGroup
          name="openWidgetByDefault"
          value={optimisationObjective}
          onChange={(e) => handleChange(e.target.value)}
          style={{ display: 'flex', fontSize: '10px' }}
          row
          key="templateObjectives"
        >
          {objectives.map((objective) => (
            <FormControlLabel
              key={objective}
              value={objective}
              control={<Radio color="primary" />}
              label={<Typography variant="body1">{objective}</Typography>}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Box>

    // <Grid item key={objective}>
    //   <FormControlLabel
    //     control={
    //       <Checkbox
    //         checked={optimisationObjective === objective}
    //         onChange={(e) => e.target.checked && handleChange(objective)}
    //         name={objective}
    //         color="primary"
    //         style={{ margin: '0px 8px', padding: 0 }}
    //       />
    //     }
    //     label={objective}
    //   />
    // </Grid>
    //))}
    //   </Grid>
    // </Box>
  );
};

const AiTemplateActions = (props: any) => {
  const { classes, loading, prompt, regenerate, handlePromptSubmit } = props;
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        // md={regenerate ? 6 : 12}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Button
          size="small"
          className={classes.gradientButton}
          style={{ width: '100%' }}
          startIcon={
            loading ? (
              <CircularProgress size={16} className={classes.spinner} />
            ) : (
              regenerate && <RefreshOutlined style={{ fontSize: 16 }} />
            )
          }
          onClick={() => {
            if (!loading) {
              handlePromptSubmit();
            }
          }}
        >
          {regenerate
            ? loading
              ? 'Regenerating with'
              : 'Regenerate with'
            : loading
            ? 'Generating with'
            : 'Generate with'}

          <span className={classes.gradientLightText}>‎ AI ✧</span>
        </Button>
      </Grid>
    </Grid>
  );
};

const SubTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="overline"
      style={{
        marginBottom: 4,
        paddingBottom: 0,
        fontWeight: 500,
        fontSize: 14,
        marginTop: 16,
      }}
    >
      {title}
    </Typography>
  );
};

const AdvancedOptions = (props: any) => {
  const { classes, title, options, selectedValue, handleChange, icons } = props;
  return (
    <Grid container>
      <SubTitle title={title} />
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {options.map((option: string, index: number) => {
          const IconComponent = icons[index];
          const md = title === 'Optimise for' ? 3 : 4;
          const isSelected = option === selectedValue;
          return (
            <Grid
              item
              xs={4}
              key={option}
              className={
                !isSelected ? classes.actionBtn : classes.actionBtnSelected
              }
              style={{
                paddingRight: 8,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                position: 'relative',
                marginBottom: 8,
              }}
              onClick={() => handleChange(option)}
            >
              <IconComponent color="inherit" style={{ padding: '8px 4px' }} />
              {option}
              {isSelected && (
                <CheckCircle
                  style={{
                    position: 'absolute',
                    bottom: 4,
                    right: 4,
                    fontSize: '12px',
                  }}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      borderRadius: '8px',
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(0.5),
    },
    gradientText: {
      background: 'linear-gradient(135deg, #FE6B8B 30%, #FF8E53 90%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold',
      display: 'inline-block',
      animation: '$shimmer 5s infinite',
    },
    gradientLightText: {
      background: 'linear-gradient(135deg, #dcdcdc 30%, #A9A9A9 90%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold',
      display: 'inline-block',
      animation: '$shimmer 5s infinite',
    },
    description: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    templateOptionTextfield: {
      width: 150,
      padding: '2px 16px',
    },
    templateObjectives: {
      marginTop: theme.spacing(2),
    },
    customDropdown: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.main,
      borderRadius: '4px',
      // border: `1px solid ${theme.palette.primary.main}`,
      color: '#fff',
      cursor: 'pointer',
      minWidth: '100px',
      justifyContent: 'space-between',
      fontSize: 14,
      fontWeight: 400,
    },
    promptBox: {
      marginTop: theme.spacing(2),
    },
    promptBar: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '0px 8px',
      // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      border: `0.1px solid #A0A0A0`,
      marginTop: theme.spacing(2),
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        right: '-2px',
        bottom: '-2px',
        left: '-2px',
        zIndex: -1,
        borderRadius: '30px',
      },
    },
    textField: {
      flex: 1,
      border: 'none',
      backgroundColor: 'rgba(255, 0, 0, 0)',
      padding: 0,
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
        border: 'none',
        '& fieldset': {
          border: 'none',
        },
      },
      '& .MuiInputBase-input': {
        // padding: "6px 14px",
        backgroundColor: 'rgba(255, 0, 0, 0)',
      },
    },
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px',
      '&:hover': {
        backgroundColor: '#333',
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    iconButtonLoading: {
      animation: 'spin 1s linear infinite',
    },
    textButtonRow: {
      display: 'flex',
      justifyContent: 'flex-start',
      // marginTop: theme.spacing(1),
    },
    textButton: {
      padding: '12px 12px',
      marginRight: theme.spacing(1),
      borderRadius: '5px',
      backgroundColor: '#f0f0f0',
      color: '#000',
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 400,
      '&:hover': {
        backgroundColor: '#d0d0d0',
      },
    },
    '@keyframes shimmer': {
      '0%': {
        backgroundPosition: '-150px 0',
      },
      '100%': {
        backgroundPosition: '150px 0',
      },
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    spinner: {
      color: '#fff',
    },
    advanceOptions: {
      marginBottom: theme.spacing(2),
    },
    actionBtn: {
      color: '#4A4A4A',
      fontSize: '14px',
      fontWeight: 500,
      // width: '20px',
      borderRadius: '5px',
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      padding: '0px 0px',
      marginRight: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        background: 'rgba(0, 0, 0, 0.1)',
        // color: 'white',
      },
    },
    actionBtnSelected: {
      backgroundColor: theme.palette.primary.main,
      fontSize: '14px',
      fontWeight: 500,
      width: '20px',
      borderRadius: '5px',
      // border: `1px solid ${theme.palette.primary.main}`,
      color: '#fff',
      padding: '0px 0px',
      marginRight: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    actionBtn2: {
      fontSize: '14px',
      fontWeight: 500,
      borderRadius: '5px',
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '2px 12px',
      background: theme.palette.primary.main,
      color: 'white',
      marginRight: 0,
      marginTop: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.resolved.main,
        border: `1px solid ${theme.palette.resolved.main}`,
      },
    },
    container: {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer', // Add cursor pointer to indicate it's clickable
    },
    content: {
      padding: theme.spacing(2),
    },
    overlay: {
      position: 'absolute',
      top: 0,
      right: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(1)})`,
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.2)', // Slightly dark background
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        transition: 'opacity 0.3s',
      },
    },
    overlayIcon: {
      color: 'white', // Icon color
    },
    containerHover: {
      '&:hover $overlay': {
        opacity: 1,
      },
    },
    quickTextPrompt: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    radioBoxWrapper: {
      marginTop: theme.spacing(2),
    },
    radioBox: {
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: 400,
      marginRight: theme.spacing(1),
    },
    gradientButton: {
      marginTop: theme.spacing(1),
      background: 'linear-gradient(135deg, #004d40 0%, #00695c 100%)', // Dark green gradient
      border: 0,
      borderRadius: 8,
      color: 'white',
      height: 48,
      padding: '0 30px',
      textTransform: 'none',
      fontSize: '16px',
      transition: 'background 0.3s, transform 0.3s',
      '&:hover': {
        background: 'linear-gradient(135deg, #00695c 0%, #004d40 100%)', // Reverse gradient on hover
        transform: 'scale(1.02)', // Slightly enlarge the button on hover
      },
    },
  });

export default withStyles(styles)(AiTemplatePrompt);

const sampleAiTemplate = {
  _id: '6645a10ac6c283a4499d4ae9',
  status: 'PENDING',
  processed: '2024-05-16T06:00:42.792Z',
  callToAction: [],
  quickReplies: [],
  templateParams: [],
  label: 'test label',
  category: 'MARKETING',
  type: 'IMAGE',
  name: 'ai_sample_template',
  format:
    'Your AI Generated template preview will be reflected here after the prompt',
  sampleMessage:
    'Your AI Generated template preview will be reflected here after the prompt',
  actionType: 'All',
  sampleMediaUrl:
    'https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124680.jpg?t=st=1717246878~exp=1717250478~hmac=88e3567f500601a4f1b109ddd9617334f886e71a0875a8d8df27ab2bf83a572b&w=1800',
  sampleCTAUrl: '',
  buttons: [
    {
      type: 'URL',
      text: 'About AiSensy',
      url: 'https://m.aisensy.com',
      example: ['https://m.aisensy.com/'],
    },
    {
      text: 'Connect with us',
      type: 'PHONE_NUMBER',
      phone_number: '918068575845',
      phoneMetadata: {
        extension: '91',
        number: '8068575845',
      },
    },
  ],
  limitedTimeOffer: {},
  parameters: 0,
  namespace: 'cb610838_5855_47ff_ae69_05fba9e51501',
  templateId: '452063877233431',
  templateLanguage: 'English',
  assistantId: '6440f80d5171d70b3e268d0a',
  clientId: '61f0624bcf0a58553378ceb4',
  assistantName: 'Addon testing',
  partnerId: '627e4a2f87860b64165c065d',
  carouselCards: [],
  createdAt: '2024-05-16T06:00:42.856Z',
  updatedAt: '2024-05-16T06:00:42.856Z',
  __v: 0,
};

const aiMedia = {
  url:
    'https://whatsapp-media-library.s3.ap-south-1.amazonaws.com/IMAGE/63403f35464ac80eba8c839f/4288451_aitechnologymicrochipbackgroundfuturisticinnovationtechnologyremix%201.jpg',
  //  "https://whatsapp-media-library.s3.ap-south-1.amazonaws.com/IMAGE/63403f35464ac80eba8c839f/4555320_aicloudconceptwithrobotarm%201.jpg"
};

const TEMPLATE_CATEGORIES = ['Utility', 'Marketing', 'Authentication'];
const SAMPLE_PROMPTS = [
  'Please generate a promotional message to give a discount of 50% off on our product boat headphones, which ends tonight',
  // 'Please generate a promotional message to give a discount of flat 25% off on all stationary items, which ends today',
];

const colors = [
  'rgb(250, 173, 32)',
  'rgb(10, 71, 76)',
  'rgb(95, 102, 205)',
  'rgb(236, 64, 122)',
];

const optimisationIcons = [TouchApp, Reply];
const moodIcons = [
  SentimentSatisfied,
  MenuBook,
  EmojiEmotions,
  SentimentVerySatisfied,
];
