import React from 'react';
import { FilterNoneOutlined, OpenInNew, Phone } from '@material-ui/icons';
import { withStyles, Tooltip, Theme } from '@material-ui/core';
import { replaceParameters } from '../../../helpers/replaceParameters';
import Box from '@material-ui/core/Box';

const InteractiveButtons = (props: any) => {
  // const [snackbar, setSnackbar] = React.useState({});
  const {
    payload,
    classes,
    theme,
    cards,
    formattedButtons,
    selectedTemplate,
    snackbar,
    setSnackbar,
  } = props;
  let { buttons = [] } = payload || {};

  const parseNewButtonsToInteractiveFormat = () => {
    const { buttons: newButtons } = selectedTemplate;
    buttons = [];
    for (let index = 0; index < newButtons?.length; index++) {
      const button = newButtons[index];
      const { type, phone_number: number, urlMetadata } = button;
      let { example } = button;
      const { text } = button;
      let url = urlMetadata?.url || button.url;

      if (url) {
        if (formattedButtons?.length) {
          const urlParameters = formattedButtons
            ?.find(
              (c: any) =>
                ['button', 'BUTTON'].includes(c.type) &&
                ['url', 'URL'].includes(c.sub_type) &&
                c.index == index,
            )
            ?.parameters?.map(({ text }: any) => text);
          const formattedURL =
            replaceParameters(url, urlParameters) ||
            (urlMetadata
              ? urlMetadata.parameters?.[0] || urlMetadata.url
              : button?.example?.[0]);
          if (formattedURL) url = formattedURL;
        }
      }

      if (type === 'COPY_CODE') {
        const copyCodeParameters = formattedButtons
          ?.find((c: any) => c.index == index)
          ?.parameters?.map((e: any) => e[e.type]);
        example = copyCodeParameters?.[0] || example;
      }

      buttons.push({ type, text, url, number, example });
    }
  };

  const parseCardButtonsToInteractiveFormat = () => {
    const { carouselIndex } = props;
    const { carouselCards } = selectedTemplate;
    const carouselButtons =
      carouselCards?.[carouselIndex]?.components?.find(
        ({ type }: any) => type === 'BUTTONS',
      )?.buttons || [];

    buttons = [];
    for (const button of carouselButtons) {
      const { type, text, phone_number: number, urlMetadata, example } = button;
      let url = urlMetadata?.url || button.url;

      if (url) {
        if (cards?.length) {
          const urlParameters = cards?.[carouselIndex]?.components
            ?.find(
              (c:any) =>
                ['button', 'BUTTON'].includes(c.type) &&
                ['url', 'URL'].includes(c.sub_type),
            )
            ?.parameters?.map(({ text }: any) => text);
          const formattedURL =
            replaceParameters(url, urlParameters) ||
            (urlMetadata
              ? urlMetadata.parameters?.[0] || urlMetadata.url
              : button?.example?.[0]);
          if (formattedURL) url = formattedURL;
        }
      }

      buttons.push({ type, text, url, number, example });
    }
  };

  if (formattedButtons?.length || selectedTemplate?.buttons?.length) {
    parseNewButtonsToInteractiveFormat();
  }

  if (cards?.length || selectedTemplate?.carouselCards?.length) {
    parseCardButtonsToInteractiveFormat();
  }

  return (
    <Box className="root">
      {buttons.map((item: any, index:number) => {
        const { type, text, example } = item;

        if (!['COPY_CODE'].includes(type) && !text) return null;

        if (['QUICK_REPLY', 'ORDER_DETAILS', 'CATALOG', 'MPM'].includes(type)) {
          return (
            <Box
              key={index}
              className={`defaultTheme ${classes.fullWidth} ${
                theme === 1 ? classes.theme1 : classes.theme2
              }`}
            >
              {text}
            </Box>
          );
        }

        if (type === 'PHONE_NUMBER') {
          return (
            <Tooltip
              arrow
              title={item.number ?? ''}
              classes={{
                arrow: classes.customArrow,
                tooltip: classes.customTooltip,
              }}
            >
              <a href={`tel:${item.number}`}>
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  className={`defaultTheme ${classes.fullWidth}  ${
                    theme === 1 ? classes.theme1 : classes.theme2
                  }`}
                >
                  <Phone className={classes.actionIcon} />
                  <Box className={classes.actionText}>{text}</Box>
                </Box>
              </a>
            </Tooltip>
          );
        }

        if (type === 'COPY_CODE') {
          return (
            <Tooltip
              arrow
              title={example}
              classes={{
                arrow: classes.customArrow,
                tooltip: classes.customTooltip,
              }}
            >
              <Box
                key={index}
                display="flex"
                alignItems="center"
                className={`defaultTheme ${classes.fullWidth}  ${
                  theme === 1 ? classes.theme1 : classes.theme2
                }`}
                onClick={() => {
                  navigator.clipboard.writeText(example);
                }}
              >
                <FilterNoneOutlined
                  style={{ transform: 'rotate(90deg)' }}
                  className={classes.actionIcon}
                />
                <Box className={classes.actionText}>Copy Code</Box>
              </Box>
            </Tooltip>
          );
        }

        return (
          <Tooltip
            arrow
            title={item.url ?? ''}
            key={index}
            classes={{
              arrow: classes.customArrow,
              tooltip: classes.customTooltip,
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={item.url ? item.url.replace(/[\[\]']+/g, '') : item.url}
            >
              <Box
                key={index}
                display="flex"
                alignItems="center"
                className={`defaultTheme ${classes.fullWidth} ${
                  theme === 1 ? classes.theme1 : classes.theme2
                }`}
              >
                <OpenInNew className={classes.actionIcon} />
                <Box className={classes.actionText}>{text}</Box>
              </Box>
            </a>
          </Tooltip>
        );
      })}
    </Box>
  );
};

const styles: any = (theme: Theme) => ({
  theme1: {
    background: 'white',
  },

  theme2: {
    background: '#ebf5f3',
  },

  fullWidth: {
    width: '100%',
    borderRadius: '15px',
    margin: '0.5em 0.5em 0.5em 0',
    boxSizing: 'border-box',
    padding: '4px 8px 6px',
    textAlign: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#4c9edc',
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: '500',
  },

  actionText: {
    maxWidth: '210px',
    overflow: 'hidden',
    whiteSpace: 'prewrap',
    textOverflow: 'ellipsis',
    color: '#4c9edc',
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: '500',
  },

  actionIcon: {
    fontSize: '16px',
    margin: '0px 4px',
    color: '#4c9edc',
  },

  customTooltip: {
    backgroundColor: 'rgb(105,105,105)',
    color: 'white',
    boxShadow: 'none',
    fontSize: '14px',
    zIndex: 100,
  },

  customArrow: {
    color: 'rgb(105,105,105)',
  },
});

export default withStyles(styles)(InteractiveButtons);
