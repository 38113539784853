import React from "react";
import { OpenInNew, Phone } from "@material-ui/icons";
import { Box, Divider, Theme, Tooltip, withStyles } from "@material-ui/core";

import templateMsgHelper from "../../../helpers/templateMsgHelper";

const ClickToAction = (props:any) => {
  const { type, payload, classes } = props;
  const { callToAction, templateCTAUrl } = payload;
  let isQuickReply = false;
  let list = [];
  
  const ActionButton = ({ type, action, text }: any) => {
    const link = templateCTAUrl
      ? templateCTAUrl.replace(/[[\]']+/g, "")
      : action;

    if (type === "phone") {
      return (
        <Box>
          <Divider />
          <Tooltip
            arrow
            title={action ?? ""}
            classes={{
              arrow: classes.customArrow,
              tooltip: classes.customTooltip,
            }}
          >
            <a href={`tel:${action}`}>
              <Box className={classes.actionBox}>
                <Phone className={classes.actionIcon} />
                <Box className={classes.actionText}>{text}</Box>
              </Box>
            </a>
          </Tooltip>
        </Box>
      );
    }

    return (
      <Box>
        <Divider />
        <Tooltip
          arrow
          title={link}
          classes={{
            arrow: classes.customArrow,
            tooltip: classes.customTooltip,
          }}
        >
          <a href={link} target="_blank" rel="noreferrer">
            <Box className={classes.actionBox}>
              <OpenInNew className={classes.actionIcon} />
              <Box className={classes.actionText}>{text}</Box>
            </Box>
          </a>
        </Tooltip>
      </Box>
    );
  };

  if (!callToAction || !callToAction.length) {
    if (type === "TEXT") {
      list = (payload.text || "")
        .split(" | [")
        .map((i:any) => i.trim().substring(0, i.length - 1));
      list.shift();
    } else if (type === "QUICK_REPLY_CARD") {
      isQuickReply = true;
      list = (payload.options || "").map(
        (i:any, index:number) => i.title || `Button_${index}`
      );
    } else if (["IMAGE", "VIDEO", "FILE"].includes(type)) {
      list = (payload.caption || "")
        .split(" | [")
        .map((i:any) => i.trim().substring(0, i.length - 1));
      list.shift();
    }
  }

  return callToAction && callToAction.length ? (
    <Box>
      {callToAction.map((item:any, index:number) => {
        const { buttonTitle, buttonValue, text } = item;
        const type = item.type === "Phone Number" ? "phone" : "url";
        const title = buttonTitle || text;

        if (!title) return null;

        return (
          <ActionButton
            key={index}
            type={type}
            text={title}
            action={buttonValue}
          />
        );
      })}
    </Box>
  ) : (
    <Box className="root">
      {list.map((item:any, index:number) => {
        let type = "text";
        let action = "";
        const validNumberPattern = /^\+*\d+$/;
        let text = item.trim();
        const parts = text.split(",");

        if (!isQuickReply && parts.length > 1) {
          const lastBlock = parts.pop();
          text = parts.join(",");
          if (templateMsgHelper.validateUrl(lastBlock.trim())) {
            type = "url";
            action = lastBlock.replace(/\[([^\]]+)\]/g, (match:any) =>
              match.substring(1, match.length - 1)
            );
          } else if (validNumberPattern.test(lastBlock.trim())) {
            type = "phone";
            action = lastBlock;
          } else {
            text = [...parts, lastBlock].join(",");
          }
        }

        if (text && text[text.length - 1] === ",")
          text = text.substring(0, text.length - 1);

        if (!text) return null;

        return (
          <ActionButton key={index} type={type} text={text} action={action} />
        );
      })}
    </Box>
  );
};

const styles:any = (theme: Theme) => ({
  root: {
    position: "relative",
  },

  actionIcon: {
    fontSize: "16px",
    margin: "0px 4px",
    color: "#4c9edc",
  },

  actionBox: {
    padding: "10px 4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  actionText: {
    maxWidth: "210px",
    overflow: "hidden",
    whiteSpace: "prewrap",
    textOverflow: "ellipsis",
    color: "#4c9edc",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: "500",
  },

  arrowTop: {
    position: "absolute",
    bottom: "calc(70px-100%)",
    width: "100%",
    "&:after": {
      content: '""',
      position: "absolute",
      right: "calc(50%  - 15px)",
      top: "8px",
      borderTop: "none",
      borderRight: "15px solid transparent",
      borderLeft: "15px solid transparent",
      borderBottom: "15px solid #f8f8f8",
    },
  },

  arrowTopMessage: {
    wordWrap: "break-word",
    marginTop: "20px",
    padding: "8px 12px",
    boxSizing: "border-box",
    lineBreak: "anywhere",
    background: "#f8f8f8",
    color: "#0000009c",
    borderRadius: "6px",
    textAlign: "center",
    width: "100%",
    fontSize: "14px",
  },

  smallScreen: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px !important",
    },
  },

  customTooltip: {
    backgroundColor: "rgb(105,105,105)",
    color: "white",
    boxShadow: "none",
    fontSize: "14px",
    zIndex: 100,
  },

  customArrow: {
    color: "rgb(105,105,105)",
  },
});

export default withStyles(styles)(ClickToAction);
