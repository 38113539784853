import React, { Component } from 'react';
import { Box, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';

class ButtonStepsGuide extends Component<Props> {
  render(): React.ReactElement {
    const { classes } = this.props;
    return (
      <Box mx={{ xs: 2, md: 0 }} py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }} className={classes.guideContainer}>
        <Typography variant="h3" color="primary" paragraph style={{ fontWeight: "bold" }}>
          Simple Steps to add WhatsApp Button to your Website
        </Typography>
        <Typography variant="h4" color="textPrimary" gutterBottom style={{fontWeight: "bold"}}>
          Generate & add a WhatsApp button to your website with AiSensy for FREE. Just follow the below-mentioned steps:
        </Typography>

        <ul>
          <li>
            <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Enter Your WhatsApp Number</Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Add your WhatsApp number with your country&apos;s dial code. E.g.- If you have an Indian number, add &apos;91&apos; behind your number.
            </Typography>
          </li>

          <li>
            <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Customize the WhatsApp Button</Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Customize the WhatsApp button&apos;s appearance to match your website&apos;s theme:
              <ol>
                <li>Button Background - This is the desired color code of your WhatsApp widget. Match it with your website theme.</li>
                <li>CTA (call to action) - Text that appears on the WhatsApp button, clicking which will prompt the WhatsApp button to pop open. E.g. Chat with Us</li>
                <li>Margin & Border - Tweak the margins & border of your WhatsApp button.</li>
                <li>Pre-filled message - This is the pre-filled text users will see when they reach your WhatsApp through the WhatsApp button</li>
                <li>Upload Branding assets—Personalize your WhatsApp button with your brand name, logo, color code, and the CTA text for the message inside the button. Under the Brand subtitle, add the status you wish to assign your WhatsApp button, e.g., typically replies in minutes, Online, etc.</li>
              </ol>
            </Typography>
          </li>

          <li>
            <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Generate snippet for your WhatsApp Button</Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Once you&apos;ve properly customized your WhatsApp widget, tap the &quot;Generate snippet&quot; button. This will give you the code you need to add to your website&apos;s head tag.
            </Typography>
          </li>
        </ul>
      </Box>
    );
  }
}

interface Props extends WithStyles<typeof styles> {}

const styles = () =>
  createStyles({
    guideContainer: {
      background: "white",
      borderRadius: "8px",
      backgroundColor: "rgb(248, 248, 248)",
    },
  });

export default withStyles(styles)(ButtonStepsGuide);
