import React, { Component } from 'react';
import { Box, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';

class WhatsAppLinkUsageGuide extends Component<Props> {
  render(): React.ReactElement {
    const { classes } = this.props;
    return (
      <Box mx={{ xs: 2, md: 0 }} py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }} className={classes.guideContainer}>
        <Typography variant="h3" color="primary" paragraph style={{ fontWeight: "bold" }}>
          How to Use WhatsApp Link?
        </Typography>
        <Typography variant="h4" color="textPrimary" gutterBottom style={{fontWeight: "bold"}}>
          There are multiple use cases for a WhatsApp Link to bring people to your WhatsApp and engage with them. Here&rsquo;s how you can use this link to drive leads to WhatsApp:
        </Typography>

        <ul>
        <li>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Add WhatsApp Link to Your Social Media Bios</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Adding your WhatsApp Link to your social media bios helps you connect with potential leads, allowing them to send messages and engage more directly. You can also create a Linktree link to add multiple links in one place on your social media bios.
        </Typography>
        </li>
        <li>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Add WhatsApp Link to Your Instagram Story</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Instagram Stories are a highly viewed feature. Here&rsquo;s how to add your WhatsApp link:
          <ol>
            <li>Download the WhatsApp Icon</li>
            <li>Tap &lsquo;+&rsquo; on the Instagram app and create a &lsquo;New Story&rsquo; post</li>
            <li>Link the WhatsApp URL and add it to the story</li>
            <li>Add it to &lsquo;New Highlights&rsquo; on your bio page</li>
          </ol>
        </Typography>
        </li>
        <li>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Share WhatsApp Link on Facebook</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Facebook remains a key marketing channel. Share your WhatsApp link in your posts, newsfeed, and captions so users can easily click to chat with you on WhatsApp.
        </Typography>
        </li>
        <li>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Share It Through Twitter Posts</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Twitter&rsquo;s search engine allows people to find exactly what they&rsquo;re looking for. Share your WhatsApp link along with your Twitter posts to help users connect with you directly.
        </Typography>
        </li>
        <li>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Share It on Pinterest</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Pinterest can be an effective marketing platform. Add your WhatsApp link to your pins so potential customers can reach out directly if they are interested in your products or services.
        </Typography>
        </li>
        <li>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>Add the Link to YouTube Video Descriptions</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          YouTube descriptions are another place to share your WhatsApp link. Many viewers check descriptions while watching videos, so adding a direct WhatsApp link can make it easy for viewers to connect with you.
        </Typography>
        </li>
        </ul>
      </Box>
    );
  }
}

interface Props extends WithStyles<typeof styles> {}

const styles = () =>
  createStyles({
    guideContainer: {
      background: "white",
      borderRadius: "8px",
      backgroundColor: "rgb(248, 248, 248)",
    },
  });

export default withStyles(styles)(WhatsAppLinkUsageGuide);
