import React, { Component } from 'react';
import { Box, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';

class WhatsAppButtonBenefits extends Component<Props> {
    render(): React.ReactElement {
        const {classes} = this.props
        return (
            <Box mx={{xs: 2, md: 0}} py={{xs:2, md: 4}} px={{xs: 2, md:4}} className={classes.guideContainer}>
                <Typography variant="h3" color="primary" paragraph style={{ fontWeight: "bold" }}>
                    Benefits of sharing WhatsApp Link
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            It&apos;s the easiest way of scoring a lead. You get the customer&apos;s name and number along with their opt-in for future WhatsApp updates, notifications and more.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            It offers a great level of personalization with 1:1 live chat. It&apos;s a &apos;foolproof&apos; way of getting the users engaged and convert them into customers.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            WhatsApp conversations have a 45-60% click-through rate, the best the industry has to offer.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="textSecondary">
                            It is 7x better than emails or SMS. Once you get your website visitors on WhatsApp, you&apos;ll have a better chance to boost your sales and growth with WhatsApp than any other platform.
                        </Typography>
                    </li>
                </ul>
            </Box>
        );
    }
}

interface Props extends WithStyles<typeof styles> {}

const styles = () => createStyles({
	guideContainer: {
    background: "white",
    borderRadius: "8px",
		backgroundColor: "rgb(248, 248, 248)"
  },
})

export default withStyles(styles)(WhatsAppButtonBenefits);
