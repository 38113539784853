import { createStyles, Grid, Theme, withStyles, WithStyles, Typography, Button, Box } from '@material-ui/core';
import React from 'react';
import FooterLogo from '../../static/footerLogo.png';
import FooterBanner from '../../static/footerbanner.png';
import YTIcon from "../../static/yt_icon.svg";
import FBIcon from "../../static/fb_icon.svg";
import IGIcon from "../../static/ig_icon.svg";



const platformLinks = [
  {
    title: "Features",
    url: "https://m.aisensy.com/features"
  },
  {
    title: "Use Cases",
    url: "https://m.aisensy.com/use-cases"
  },
  {
    title: "Pricing",
    url: "https://m.aisensy.com/pricing"
  },
  {
    title: "Blog",
    url: "https://m.aisensy.com/blog"
  },
  {
    title: "Contact Us",
    url: "https://m.aisensy.com/contact-us"
  },
  {
    title: "Request A Demo",
    url: "https://m.aisensy.com/demo"
  },
]

const resourcesLinks = [
  {
    title: "Learning Hub",
    url: "https://help.aisensy.com/"
  },
  {
    title: "WhatsApp Business API",
    url: "https://help.aisensy.com/en/collections/3008963-whatsapp-business-api"
  },
  {
    title: "Experience Notifications",
    url: "https://m.aisensy.com/integrations/experience-whatsapp-notifications"
  },
  {
    title: "Create WhatsApp Link",
    url: "https://create.aisensy.com/generate-whatsapp-link"
  },
  {
    title: "WhatsApp Button",
    url: "https://create.aisensy.com/generate-whatsapp-button"
  },
  {
    title: "Learn Chatbot Development",
    url: "https://help.aisensy.com/en/collections/3014791-dialogflow-chatbot-tutorial"
  },
]
const connectLinks = [
  {
    icon: YTIcon,
    url: "https://www.youtube.com/channel/UC8AtOu094QP5jBklnWoJMoA"
  },
  {
    icon: IGIcon,
    url: "https://www.instagram.com/aisensy_official/"
  },
  {
    icon: FBIcon,
    url: "https://www.facebook.com/groups/197843048482606"
  },
]
const Footer = (props: Props): React.ReactElement  => {
	const { classes } = props
	return (
		<div className={classes.root}>
      {/* <Grid container justify="center" className={classes.signupWrapper}>
        <Grid item xs={12} md={10} lg={7} >
          <Grid 
            container
            justify="space-between"
            alignItems="center"
            className={classes.signupFormContainer}
          >
            <Grid item xs={12} sm ={6}md={5}>
              <Typography variant="h2" gutterBottom>
                Get Started with AiSensy Platform
              </Typography>
              <Typography variant="body1" gutterBottom>
                Broadcast & Automate Messages, Enable Multi-Human Live Chat Support, Install A.I. Chatbots & more 
              </Typography>
              <Button 
                color="primary"
                size="large"
                style={{marginTop: "20px"}}
                variant="outlined"
                href="https://m.aisensy.com/pricing"
                target="_blank"
                className={classes.hrefBtn}
              >
                Get Started Now
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <img src={FooterBanner} className={classes.footerBanner}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container justify="center" className={classes.footerWrapper}>
        <Grid item xs={12} md={10} lg={7} >
          {/* All content goes in here */}
          <Grid 
            className={classes.footerContainer} 
            container 
            spacing={2} 
            justify="space-between"
          >
            <Grid item xs={12} sm={12} md>
              {/* AiSensy, copyright, T&C, Privacy Policy */}
              <a href="https://aisensy.com" target="_blank" rel="noreferrer">
                <img src={FooterLogo} className={classes.logo}/>
              </a>
              <Typography variant="body1">
                #1 Whatsapp Marketing, CRM & Chatbot Platform
              </Typography>
              <Box my={2}></Box>
            </Grid>
            <Grid item xs={12}  sm={4} md>
              <Typography variant="h3" paragraph style={{fontWeight: 800}}>
                Platform
              </Typography>
              {
                platformLinks.map(p => {
                  return (
                    <Typography variant="h5" paragraph key={p.title}>
                      <a href={p.url} target="_blank" rel="noreferrer">
                        {p.title}
                      </a>
                    </Typography>
                  )
                })
              }
            </Grid>
            <Grid item xs={12} sm={4} md>
              <Typography variant="h3" paragraph style={{fontWeight: 800}}>
                Resources
              </Typography>
              {
                resourcesLinks.map(p => {
                  return (
                    <Typography variant="h5" paragraph key={p.title}>
                      <a href={p.url} target="_blank" rel="noreferrer">
                        {p.title}
                      </a>
                    </Typography>
                  )
                })
              }
            </Grid>
            <Grid item xs={12} sm={4} md>
              {/* Get in touch - Book a demo, Connect on social media channels, Questions or Feedbacks */}
              <Typography variant="h3" paragraph style={{fontWeight: 800}}>
                Connect 
              </Typography>
              <Box display="flex">
                {
                  connectLinks.map((p, index) => {
                    return (
                      <a 
                        href={p.url} 
                        target="_blank" 
                        rel="noreferrer" 
                        key={index}
                        style={{marginRight: 12}}
                      >
                        <img
                          src={p.icon}
                          className={classes.socialIcon}
                        />
                      </a>
                    )
                  })
                }
              </Box>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>  
		</div>
	)
}

interface Props extends WithStyles<typeof styles> {
	// name: string,
	// number: string
}

const styles = (theme: Theme) => createStyles({
	"root": {
		width: "100%",
    paddingBottom: "2%",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "10%",
    },
    "& a": {
      textDecoration: "none",
      color: "black"
    },
    "& a:hover": {
      textDecoration: "none"
    }
	},
  signupWrapper: {
    // backgroundColor: "rgb(245, 245, 245)",
    marginBottom: "4%",
    [theme.breakpoints.down('sm')]: {
      boxSizing: "border-box",
      padding: "0px 15px",
      marginTop: "12%"
    }
  },
  signupFormContainer: {
    minHeight: 350,
    width: "100%",
  },
  footerWrapper: {
    backgroundColor: "white",
    minHeight: 350,
    [theme.breakpoints.down('sm')]: {
      boxSizing: "border-box",
      padding: "0px 15px"
    }
  },
  footerContainer: {
    boxSizing: "border-box",
    width: "100%",
    padding: "40px 0px",
    // color: "white"
  },
  logo: {
    height: "60px",
    marginBottom: "0px",
  },
  footerBanner: {
    // width: "100%"
    maxHeight: "400px",
    [theme.breakpoints.down('sm')]: {
      maxHeight: "300px"
    }
  },
  socialIcon: {
    width: 20,
    maxHeight: 20
  },
  hrefBtn: {
    color: `${theme.palette.primary.main} !important`
  }
})

export default withStyles(styles)(Footer);