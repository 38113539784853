// const format = "This is {{1}} and the name of company is {{2}}."
// const sample = "This is [Ajay] and the name of company is [Triny]."

function ifFloating(format: string) {
  const validBracePattern = /\{\{[0-9]+\}\}/g; // only numbers wrapped by {{}} e.g {{1}}
  const validFloatingPattern = /^(?!\s*$)/g; // string with atleast one non-space character
  let ifFloating = false;
  // Check if each line as non-floating paramters
  format.split('\n').forEach((line) => {
    // Check if line has any content to run checks
    if (!!line.trim()) {
      const replacedText = line.replace(validBracePattern, (match) => '');
      const valid = validFloatingPattern.test(replacedText);
      if (!valid) {
        ifFloating = true;
      }
      // console.log({
      //   replacedText,
      //   valid,
      // });
    }
  });
  // console.log({ ifFloating });
  return ifFloating;
}

//Check numbers of params allowed with respect to the message length
function allowedRelativeParamLimit(format: string) {
  const paramCount = extractParameters(format).length;
  const formattedText = format.replace(/\s+/g, ' ');
  const words = formattedText.split(' ').length - paramCount;
  const minWords = 2 * paramCount + 1;
  if (words >= minWords) return true;
  return false;
}

function validateFormat(format: string) {
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  const invalidBracePattern = /(\{|\})/g;
  const invalidBracketPattern = /(\[|\])/g;
  // no more than 1,024 characters
  if (format.length > 1024) {
    return {
      valid: false,
      msg: 'Template format length over 1024 characters.',
    };
  }
  // check consecutive empty lines
  if (
    format
      .split('\n')
      .map((i) => i.trim())
      .join('\n')
      .includes('\n\n\n')
  ) {
    return {
      valid: false,
      msg: 'Consecutive empty lines not allowed in template format',
    };
  }
  // check for floating parameters
  // if (ifFloating(format)) {
  //   return {
  //     valid: false,
  //     msg: "Floating paramters aren't allowed in template format",
  //   };
  // }
  // check if the numbers of parameters are within the specified limit with respect to message length or words
  if (!allowedRelativeParamLimit(format)) {
    return {
      valid: false,
      msg:
        'This template contains too many variable parameters relative to the message length. You need to decrease the number of parameters or increase your message word count.',
    };
  }

  // replace valid {{}} with param value only
  const replacedText = format.replace(validBracePattern, (match) =>
    match.substring(2, match.length - 2),
  );
  // if { or } exists anywhere return false else true
  const ifCurlyValid = !invalidBracePattern.test(replacedText);
  if (!ifCurlyValid) {
    return { valid: false, msg: 'Extra curly braces found in template format' };
  }
  // if [ or ] exists anywhere 	return false
  const ifBracketValid = !invalidBracketPattern.test(replacedText);
  if (!ifBracketValid) {
    return {
      valid: false,
      msg: "'[' or ']' aren't allowed in template format.",
    };
  }

  return { valid: true, msg: 'Format seems legit.' };
}

function extractParameters(format: string) {
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  const parameters: any = [];

  function getParameter(match: string) {
    parameters.push(match.substring(2, match.length - 2));
  }

  const replacedText = format.replace(validBracePattern, getParameter as any);
  // console.log({parameters});
  return parameters;
}

function compareFormatAndSample(
  format: string,
  sample: string,
  strictOrdering: boolean,
) {
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  const validBracketPattern = /\[([^\]]+)\]/g;
  const braceFreeText = format.replace(validBracePattern, (match) => '');
  const bracketFreeText = sample.replace(validBracketPattern, (match) => '');

  if (
    strictOrdering &&
    (format.match(/\{\{[0-9]+\}\}/g) || []).some(
      (i, index) => i !== `{{${index + 1}}}`,
    )
  ) {
    return {
      valid: false,
      msg: 'Invalid parameter ordering',
    };
  }

  // console.log(
  //   format,
  //   bracketFreeText,
  //   braceFreeText,
  //   braceFreeText == bracketFreeText
  // );

  if (braceFreeText === bracketFreeText) {
    return { valid: true, msg: 'Format seems legit.' };
  } else {
    return {
      valid: false,
      msg: "Sample message doesn't match with template format",
    };
  }
}

function validateUrl(value: string) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value,
  );
}

const validateTemplateButtons = (buttons: any[]) => {
  const buttonTextPattern = /^(?!\s*$).{0,25}$/;
  const buttonsLen = buttons?.length || 0;

  if (!buttonsLen) {
    return { valid: true, msg: 'No buttons found!' };
  }

  if (buttonsLen > 10) {
    return { valid: false, msg: 'Maximum 10 buttons allowed!' };
  }

  // Buttons type, count & other meta-data
  const buttonsMetaData: any = {
    URL: { count: 0, maxCount: 2 },
    PHONE_NUMBER: { count: 0, maxCount: 1 },
    QUICK_REPLY: { count: 0, maxCount: 10 },
  };

  // If there are less than or eq to 2 buttons, then dont validate combination
  if (buttonsLen <= 2) {
    for (const button of buttons) {
      buttonsMetaData[button.type].count++;
    }
  }

  // If there are more than 2 buttons, then validate combination
  if (buttonsLen > 2) {
    let prevButton = buttons[0].type;
    buttonsMetaData[prevButton].count++;
    // Validate combination of buttons & update their counts
    for (let idx = 1; idx < buttonsLen - 1; idx++) {
      const currentButton = buttons[idx].type;
      const nextButton = buttons[idx + 1].type;
      // Check if a non quick reply button is found in the middle of the buttons list
      if (
        prevButton === 'QUICK_REPLY' &&
        currentButton !== 'QUICK_REPLY' &&
        nextButton === 'QUICK_REPLY'
      ) {
        return { valid: false, msg: 'Invalid Button Combination!' };
      }
      buttonsMetaData[currentButton].count++;
      prevButton = currentButton;
    }
    buttonsMetaData[buttonsLen - 1].count++;
  }

  // Validate button counts
  for (const key in buttonsMetaData) {
    if (buttonsMetaData[key].count > buttonsMetaData[key].maxCount) {
      return {
        valid: false,
        msg: `${key} button count is more than ${buttonsMetaData[key].maxCount}`,
      };
    }
  }

  for (const button of buttons) {
    const { type, text, url, example } = button;

    if (type === 'URL') {
      if (!validateUrl(url)) {
        return { valid: false, msg: `Invalid Card Media URL` };
      }

      const params = extractParameters(url);
      if (params?.length && !example?.length) {
        return {
          valid: false,
          msg: `Missing Card URL Button Params Sample Value`,
        };
      }
    }

    if (type === 'PHONE_NUMBER') {
      const validNumberPattern = /^\d+$/;

      const phone_number = button.extension + button.number;

      if (!validNumberPattern.test(phone_number)) {
        return { valid: false, msg: `Invalid Phone Number Format` };
      }
    }

    if (!text || !buttonTextPattern.test(text)) {
      return { valid: false, msg: `Invalid Card ${type} Text` };
    }
  }

  return { valid: true, msg: `Buttons seems legit` };
};

const validateCarouselTemplate = (data: any) => {
  const { carouselTemplates, buttons } = data;

  if (buttons.length > 2) {
    return { valid: false, msg: 'Max 2 buttons allowed' };
  }

  const buttonResult = validateTemplateButtons(buttons);

  if (!buttonResult.valid) {
    return buttonResult;
  }

  const templateFooterMandatory = carouselTemplates.some(
    (template: any) => template.templateFooter,
  );

  for (const template of carouselTemplates) {
    const { templateFooter } = template;

    if (!templateFooter && templateFooterMandatory) {
      return {
        valid: false,
        msg: 'Template body is required for all cards',
      };
    }

    const formatResult = validateFormat(templateFooter);

    if (!formatResult.valid) {
      return formatResult;
    }
  }

  return {
    valid: true,
    msg: 'Carousel format seems legit.',
  };
};

const validateLimitedTimeOffer = (
  limitedTimeOffer: any = {},
  category: string,
  buttons: any = [],
) => {
  if (!limitedTimeOffer.text || limitedTimeOffer.text.length > 16) {
    return {
      valid: false,
      msg: 'Please provide Limited Time Offer Text upto 16 characters!',
    };
  }

  if (category !== 'MARKETING') {
    return {
      valid: false,
      msg:
        'Limited Time Offer Templates can only have MARKETING as their category!',
    };
  }

  if (
    limitedTimeOffer.has_expiration &&
    !buttons.find(({ subType }: any) => subType === 'COUPON_CODE')
  ) {
    return {
      valid: false,
      msg: 'Coupon Code button is mandatory when offer expiration is enabled!',
    };
  }

  return { valid: true, msg: 'No errors found!' };
};

const formatToSendableLimitedTimeOfferFormat = (limitedTimeOffer: any) => {
  if (!limitedTimeOffer?.has_expiration) return;

  const formattedLimitedTimeOffer = {
    type: 'limited_time_offer',
    parameters: [
      {
        type: 'limited_time_offer',
        limited_time_offer: {
          expiration_time_ms: Date.now() + 1000 * 60 * 60,
        },
      },
    ],
  };

  return formattedLimitedTimeOffer;
};

export default {
  compareFormatAndSample,
  extractParameters,
  validateFormat,
  validateUrl,
  validateTemplateButtons,
  validateCarouselTemplate,
  validateLimitedTimeOffer,
  formatToSendableLimitedTimeOfferFormat,
};
