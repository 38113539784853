import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Image } from "@material-ui/icons";

const cardStyle = {
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
  boxShadow: "none",
  margin: "16px",
  width: "auto",
};

const headerStyle = {
  borderBottom: "1px dashed #000",
  paddingBottom: "8px",
  marginBottom: "12px",
};

const OrderCard = ({ type, total, productName, quantity }:any) => {
  return (
    <Card style={cardStyle}>
      <CardContent>
        {type === "ORDER_DETAILS" && (
          <Typography style={headerStyle} variant="h6" component="h2">
            ORDER # {"{{Order number}}"}
          </Typography>
        )}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Box
            style={{
              width: "40px",
              height: "40px",
              background: "#a3a7ac",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image style={{ color: "#e6e5e5", fontSize: "32px" }} />
          </Box>
          <Box>
            <Typography component="p">
              {productName ? productName : "{{Product name}}"}
            </Typography>
            <Typography color="textSecondary">
              Quantity {quantity ? quantity : "{{#}}"}
            </Typography>
          </Box>
        </Box>
        {type === "ORDER_DETAILS" && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            style={{
              borderTop: "1px dashed #000",
              padding: "8px 0px",
              marginTop: "12px",
            }}
          >
            <Typography variant="h5" component="h2">
              Total
            </Typography>
            <Typography variant="h5" component="h2">
              ₹{total ? total * quantity : "{{#}}"}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default OrderCard;

// Example usage:
// <OrderCard orderNumber="123456" productName="Widget" quantity="3" total="150.00" />
