import React, { Component } from 'react';
import { Box, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';

class StepsGuideCard extends Component<Props> {
  render(): React.ReactElement {
    const {classes} = this.props
    return (
      <Box mx={{ xs: 2, md: 0 }} py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }} className={classes.guideContainer}>
        <Typography variant="h3" color="primary" paragraph style={{ fontWeight: "bold" }}>
          3 Steps to Create Your Unique WhatsApp Link
        </Typography>
        <Typography variant="h4" color="textPrimary" gutterBottom style={{fontWeight: "bold"}}>
          Generate your unique WhatsApp Link with AiSensy for FREE. To create this link, just follow the below-mentioned steps:
        </Typography>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>1. Enter Your WhatsApp Number:</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Select your country, country code, and fill in the WhatsApp number where you wish to receive messages.
        </Typography>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>2. Add a Custom Message:</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Write a pre-filled message for the users that they will see on WhatsApp after clicking your WhatsApp Link. For example: &ldquo;Hey, I had a query.&rdquo;
        </Typography>
        <Typography variant="h5" color="textPrimary" style={{fontWeight: "bold"}}>3. Generate Your WhatsApp Link:</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Finally, hit the &lsquo;Generate Link&rsquo; button. AiSensy&rsquo;s WhatsApp link generator provides you with both a WhatsApp Link and a WhatsApp QR code that you can use on product packaging and other places.
        </Typography>
      </Box>
    );
  }
}

interface Props extends WithStyles<typeof styles> {}

const styles = () => createStyles({
	guideContainer: {
    background: "white",
    borderRadius: "8px",
		backgroundColor: "rgb(248, 248, 248)"
  },
})

export default withStyles(styles)(StepsGuideCard);
