import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faq } from '../../types/link.type';


class FAQAccordian extends React.Component<Props> {
  render(): React.ReactElement {
    const { classes, faqData, title } = this.props;

    return (
      <Box mx={{ xs: 2, md: 10 }} py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
        <Typography variant='h3' style={{fontWeight: 600, marginBottom: "1rem", textAlign: "center"}} >{title}</Typography>
        {faqData.map((faq, index) => (
          <Accordion key={index} className={classes.accordion} style={{ padding: "6px 8px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{fontWeight: "bold"}}/>}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              style={{flexDirection: 'row-reverse', gap: 10}}
            >
              <Typography variant='h4' color="textPrimary" style={{ fontWeight: "bold" }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1' color="textSecondary">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  }
}

interface Props extends WithStyles<typeof styles> {
  faqData: faq[];
  title: string;
}

const styles = (theme: Theme) =>
  createStyles({
    accordion: {
      '&:hover': {
        borderColor: theme.palette.primary.main, // Primary color on hover
      },
      transition: 'border-color 0.3s ease',
      borderColor: '#d8dde6',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: 2,
      boxShadow: 'none',
      marginBottom: 14,
      borderRadius: 4,
    },
  });

export default withStyles(styles)(FAQAccordian);
