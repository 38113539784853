import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from './pages/HomePage/HomePage';
import WhatsAppLinkPage from './pages/WhatsAppLinkPage/WhatsAppLinkPage';
import WhatsAppBtnPage from './pages/WhatsAppBtnPage/WhatsAppBtnPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import InstamojoStoreIntegrationPage from './pages/InstamojoStoreIntegrationPage/InstamojoStoreIntegrationPage';
import Navbar from './commons/Navbar/Navbar';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import Footer from './commons/Footer/Footer';
import AiTemplatePrompt from './pages/AiTemplate/AiTemplatePrompt';
// import "./types/material-ui-color.d.ts";

type whatsAppButtonOptions = {
  env: string;
  linkid: string;
  phoneNumber: string;
  variant: string;
  btnBackground: string;
  btnCTA: string;
  mb: string;
  ml: string;
  mr: string;
  borderRadius: string;
  prefilledMsg: string;
  position: string;
  brandName: string;
  brandSub: string;
  brandColor: string;
  brandImgUrl: string;
  widgetBtnCTA: string;
  openWidgetByDefault: string;
  onscreenMsg: string;
  widgetOnMobile: string;
};

declare global {
  interface Window {
    CreateWhatsAppButtonAndWidget(options: whatsAppButtonOptions): void;
  }
}

const App = (props: Props): React.ReactElement => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.routeContainer}>
        <Router>
          <Navbar />
          <Switch>
            {/* <Route exact path="/" component={HomePage}/> */}
            <Route path="/generate-whatsapp-link" component={WhatsAppLinkPage} />
            <Route path="/generate-whatsapp-button" component={WhatsAppBtnPage} />
            <Route path="/" component={AiTemplatePrompt} />
            {/* <Route path="/integrations/instamojo" component={InstamojoStoreIntegrationPage} /> */}
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </div>
      <Footer />
    </div>
  );
};

interface Props extends WithStyles<typeof styles> {
  // name: string,
  // number: string
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw !important',
      backgroundColor: 'white',
      boxSizing: 'border-box',
      overflowX: 'hidden',
      overflowY: 'auto',
      // paddingTop: theme.spacing(1)
    },
    routeContainer: {
      // height: "100vh",
      width: '100% !important',
    },
  });

export default withStyles(styles)(App);
